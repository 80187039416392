import React, { useEffect, useState, lazy, Suspense } from "react";
import { v4 as uuidv4 } from "uuid";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import useMediaQuery from "@mui/material/useMediaQuery";

import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from "@mui/icons-material/History";
import InfoIcon from "@mui/icons-material/Info";
import StopIcon from "@mui/icons-material/Stop";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";

import ExportFlightWaypointsMenu from "./exportFlightWaypointsDialog";
import { FlightHistoryDialog } from "./flightHistoryDialog";
import { FlightInfoDialog } from "./flightInfoDialog";
import FlightCreator from "./flightCreator";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomPagination } from "../../customPagination";
import { PieChart } from "@mui/x-charts/PieChart";
import { ConvertISOToDate, GetColorFromState, getOperationDateFromIsoString } from "../../util";
import { getOperationEditable, getOperationPriority } from "./opUtil";

import { useUserAuth } from "../../contexts/authContext";
import { useMap } from "../../contexts/mapContext";
import { useSocket } from "../../contexts/socketContext";
import { useEnv } from "../../contexts/envContext";

import { useNavigate } from "react-router-dom";

import * as CONSTANT from "./opConstants";

const LAANCDetailsDialog = lazy(() => import("./laancDetailsDialog"));

const OperatorDashboard = () => {
    const [upcomingOperations, setUpcomingOperations] = useState([]);
    const [allOperations, setAllOperations] = useState([]);
    const [searchedOperations, setSearchedOperations] = useState([]);

    const [pieChartIndex, setPieChartIndex] = useState(0);
    const [pieCharts, setPieCharts] = useState([]);
    const [totalFlightTime, setTotalFlightTime] = useState("0hr 0m");
    const [totalDrones, setTotalDrones] = useState("0");

    const [downloadWaypointDialogOpen, setDownloadWaypointDialogOpen] = useState(false);
    const [downloadWaypointOperation, setDownloadWaypointOperation] = useState(null);

    const [selectedFlightHistoryFlight, setSelectedFlightHistoryFlight] = useState(null);
    const [flightHistoryDialogOpen, setFlightHistoryDialogOpen] = useState(false);

    const [infoDialogFlight, setInfoDialogFlight] = useState(null);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);

    const [flightDetailsVolume, setFlightDetailsVolume] = useState(null);
    const [flightDetailsDialogOpen, setFlightDetailsDialogOpen] = useState(false);

    const [editFlightDialogOpen, setEditFlightDialogOpen] = useState(false);
    const [editFlightVolume, setEditFlightVolume] = useState(null);

    const { user, setUser, handleFailedFetch, userOperationalStates } = useUserAuth();
    const { drones, getDrones } = useMap();
    const { socket, publishedOperations } = useSocket();
    const { laancOn } = useEnv();

    const navigate = useNavigate();
    const isDesktop = useMediaQuery("(min-width:900px)");

    const columns = [
        {
            field: "info",
            headerName: "",
            minWidth: 50,
            maxWidth: 50,
            flex: 1,
            display: "flex",
            renderCell: ({ row }) => {
                return (
                    <Tooltip title="Information">
                        <IconButton
                            size="medium"
                            onClick={() => {
                                setInfoDialogFlight(row);
                                setInfoDialogOpen(true);
                            }}
                            id={`info-${row.volumeId}`}
                        >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 175,
            flex: 1,
            editable: false
        },
        {
            field: "vehicle_uuid",
            headerName: "Vehicle",
            minWidth: 175,
            flex: 1,
            editable: false,
            valueFormatter: (value) => {
                return getDroneFromVehicleUuid(value);
            }
        },
        {
            field: "time_start",
            headerName: "Start Date",
            minWidth: 200,
            flex: 1,
            editable: false,
            valueFormatter: (value) => {
                return ConvertISOToDate(value);
            }
        },
        {
            field: "time_end",
            headerName: "End Date",
            minWidth: 200,
            flex: 1,
            editable: false,
            valueFormatter: (value) => {
                return ConvertISOToDate(value);
            }
        },
        {
            field: "bvlos",
            headerName: "BVLOS",
            minWidth: 100,
            flex: 1,
            editable: false,
            valueFormatter: (value) => {
                return value === true ? "BVLOS" : "VLOS";
            }
        },
        {
            type: "actions",
            field: "actions",
            minWidth: 125,
            headerName: "Actions",
            cellClassName: "actions",
            getActions: ({ row }) => {
                const arr = [
                    <Tooltip key={1} title="History">
                        <GridActionsCellItem
                            icon={<HistoryIcon />}
                            label="History"
                            onClick={() => {
                                setSelectedFlightHistoryFlight(row);
                                setFlightHistoryDialogOpen(true);
                            }}
                            id={`history-${row.volumeId}`}
                        />
                    </Tooltip>
                ];

                const hasEnded = new Date(row.time_end).getTime() <= new Date().getTime() ? true : false;
                // Allow permanent delete if the operation is in a final state (deleted or time expired).
                const deletable = row.state === "DELETED" || hasEnded ? true : false;

                if (deletable) {
                    arr.push(
                        <Tooltip key={2} title="Delete">
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={() => handleDeleteFlightClick(row)}
                                id={`perm-delete-${row.volumeId}`}
                            />
                        </Tooltip>
                    );
                }
                return arr;
            }
        }
    ];

    useEffect(() => {
        getDrones();
    }, []);

    useEffect(() => {
        const user_operations = publishedOperations
            .filter(({ pilot_uuid }) => {
                return pilot_uuid === user.pilot_uuid;
            })
            .sort((a, b) => {
                return new Date(a.time_start).getTime() - new Date(b.time_start).getTime();
            });
        setUpcomingOperations(user_operations);
    }, [publishedOperations]);

    useEffect(() => {
        if (user && user.pilot_uuid && Array.isArray(drones) === true && drones.length > 0) {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            };
            fetch(`/api/op/getuser/${user.pilot_uuid}`, requestOptions)
                .then((res) => (res.ok ? res.json() : Promise.reject(res)))
                .then((data) => {
                    setAllOperations(setupVolumeIds(data));
                    setSearchedOperations(setupVolumeIds(data));

                    getTotalDrones(data);
                    getTotalFlightTime(data);
                    getPieChartData(data);
                })
                .catch((err) => handleFailedFetch(err));
        }
    }, [drones]);

    const setupVolumeIds = (volumes) => {
        const volumeCount = {};
        return volumes.map((volume) => {
            const name = volume.name;
            const count = volumeCount[name] ? volumeCount[name] + 1 : 1;
            volumeCount[name] = count;
            return {
                ...volume,
                volumeId: `${name}-${count}`
            };
        });
    };

    const getDroneFromVehicleUuid = (vehicle_uuid) => {
        const drone = drones.find((drone) => {
            return drone.vehicle_uuid === vehicle_uuid;
        });
        if (drone !== undefined) {
            return drone.name;
        } else {
            return "Unknown";
        }
    };
    const getTotalDrones = (flights) => {
        const totalVehicles = flights.reduce((acc, { vehicle_uuid }) => {
            if (acc.includes(vehicle_uuid) === false) {
                acc.push(vehicle_uuid);
            }
            return acc;
        }, []);
        setTotalDrones(totalVehicles.length);
    };
    const getTotalFlightTime = (flights) => {
        const totalDuration = flights.reduce((acc, { time_start, time_end }) => {
            const startTime = new Date(time_start).getTime();
            const endTime = new Date(time_end).getTime();
            const duration = endTime - startTime;

            acc += duration;
            return acc;
        }, 0);

        const hours = Math.floor(totalDuration / (1000 * 60 * 60));
        const minutes = Math.floor((totalDuration % (1000 * 60 * 60)) / (1000 * 60));
        setTotalFlightTime(`${hours}hrs ${minutes}m`);
    };
    const getPieChartData = (flights) => {
        const charts = [];
        const fields = [
            { title: "Flights Per Vehicle", field: "vehicle_uuid" },
            { title: "Flights Per Operation Type", field: "bvlos" },
            { title: "Flights Per Priority", field: "priority" }
        ];
        fields.forEach(({ title, field }) => {
            const arr = [];
            const mp = flights.reduce((acc, flight) => {
                let label = "";
                if (field === "vehicle_uuid") {
                    label = getDroneFromVehicleUuid(flight.vehicle_uuid);
                } else if (field === "bvlos") {
                    label = flight[field] === true ? "BVLOS" : "VLOS";
                } else {
                    label = getOperationPriority(flight);
                }
                const count = acc.get(label);
                if (count !== undefined) {
                    const updated_count = count + 1;
                    acc.set(label, updated_count);
                } else {
                    acc.set(label, 1);
                }
                return acc;
            }, new Map());

            mp.forEach((count, key) => {
                if (key.length > 15) {
                    key = key.substring(0, 15).trim() + "...";
                }
                arr.push({ id: key, label: key, value: count });
            });
            charts.push({
                title: title,
                series: [{ paddingAngle: 5, innerRadius: "65%", outerRadius: "80%", data: arr }]
            });
        });
        setPieCharts(charts);
    };
    const handleDeleteClick = (operation) => {
        if (confirm("Are you sure you want to delete this operation?") === true) {
            const updated_operation = JSON.stringify({
                ...operation,
                state: "DELETED",
                updated_user_id: user.id
            });
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: updated_operation
            };
            fetch("/api/op/delete", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .catch((err) => handleFailedFetch(err));
        }
    };
    const handleActivateClick = (operation) => {
        if (confirm("Are you sure you want to activate this operation?") === true) {
            const updated_operation = JSON.stringify({
                ...operation,
                state: "ACTIVATED",
                version: parseInt(operation.version) + 1,
                updated_user_id: user.id
            });
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: updated_operation
            };
            fetch("/api/op/updatePublish", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .catch((err) => handleFailedFetch(err));
        }
    };
    const handleEndClick = (operation) => {
        if (confirm("Are you sure you want to end this operation?") === true) {
            const updated_operation = JSON.stringify({
                ...operation,
                state: "ENDED",
                version: parseInt(operation.version) + 1,
                updated_user_id: user.id
            });
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: updated_operation
            };
            fetch("/api/op/delete", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .catch((err) => handleFailedFetch(err));
        }
    };
    // const handleSettingsClick = () => {
    //     console.log("this is for OIS and DAA configurations");
    // };
    const handleSearchValueChange = (e) => {
        const search_value = e.target.value;
        if (search_value !== undefined && search_value.length) {
            const operations = allOperations.filter((operation) => {
                const operation_name = operation.name.toLowerCase();
                return operation_name.includes(search_value.toLowerCase());
            });
            setSearchedOperations(operations);
        } else {
            setSearchedOperations(allOperations);
        }
    };
    const handleRegisterPilotClick = () => {
        const updated_user = {
            ...user,
            pilot_uuid: uuidv4()
        };
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updated_user)
        };
        fetch("api/account/update", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                setUser(updated_user);
                socket.emit("storeUserChatSocket", updated_user);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const handleDeleteFlightClick = (row) => {
        if (confirm("This will permanently delete this flight from your account and cannot be undone. Do you wish to continue?") === true) {
            const operation = row;
            if (operation.flight_json && operation.flight_json.laanc && operation.flight_json.laanc.volumes) {
                operation.flight_json.laanc.volumes.forEach((vol) => {
                    vol.state = CONSTANT.LAANC_DELETED;
                });
            }
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(operation)
            };
            fetch("/api/op/perm/delete", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then(() => {
                    const updated_flights = allOperations.filter(({ flight_uuid }) => {
                        return flight_uuid !== operation.flight_uuid;
                    });
                    setAllOperations(updated_flights);
                    getTotalDrones(updated_flights);
                    getTotalFlightTime(updated_flights);
                    getPieChartData(updated_flights);

                    const updated_rows = searchedOperations.filter(({ flight_uuid }) => {
                        return flight_uuid !== operation.flight_uuid;
                    });
                    setSearchedOperations(updated_rows);
                })
                .catch((err) => handleFailedFetch(err));
        }
    };
    const handleDetailsButtonClicked = (operation) => {
        setFlightDetailsDialogOpen(true);
        setFlightDetailsVolume(operation);
    };
    const handleEditFlightButtonClick = (operation) => {
        setEditFlightDialogOpen(true);
        setEditFlightVolume(operation);
    };
    return editFlightDialogOpen ? (
        <FlightCreator
            setFlightCreatorOpen={setEditFlightDialogOpen}
            flightUUID={editFlightVolume.flight_uuid}
            publishedFlight={true}
            flightState={editFlightVolume.state}
        />
    ) : (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4, ml: "unset" }}>
            <Grid container spacing={2}>
                {/* welcome */}
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 4,
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: { xs: "flex-start", sm: "center" },
                            justifyContent: "space-between",
                            gap: 2
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "row", sm: "column" },
                                gap: 1,
                                justifyContent: { xs: "space-between", sm: "unset" },
                                width: { xs: "100%", sm: "unset" }
                            }}
                        >
                            <Typography variant="h5" gutterBottom id="hello">
                                Hello{user.first_name ? ` ${user.first_name}` : ""}
                            </Typography>

                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Button size="small" variant="outlined" onClick={() => navigate("/account")} id="viewProfile">
                                    View Profile
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 3 }}>
                            {[
                                { title: "Flights", value: allOperations.length, tooltip: "Total amount of flights created" },
                                { title: "Drones", value: totalDrones, tooltip: "Total amount of drones flown" },
                                { title: "Flight Time", value: totalFlightTime, tooltip: "Total time ellapsed from each operations start to finish" }
                            ].map(({ title, value, tooltip }, i) => (
                                <Tooltip key={i} title={tooltip}>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <Typography variant="body1" gutterBottom>
                                            {title}
                                        </Typography>
                                        <Typography variant="h6" gutterBottom sx={{ textAlign: "center", whiteSpace: "nowrap" }} id={title}>
                                            {value}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            ))}
                        </Box>
                    </Paper>
                </Grid>

                {/* active user flights */}
                <Grid item xs={12} md={7}>
                    <Paper
                        sx={{ p: 2, height: { xs: "unset", sm: 305 }, display: "flex", flexDirection: "column", alignItems: "flex-start", overflowY: "auto" }}
                    >
                        <Typography variant="h6">Upcoming Flights</Typography>

                        <Box sx={{ width: "100%" }}>
                            {Array.isArray(upcomingOperations) && upcomingOperations.length > 0 ? (
                                <List dense={true} sx={{ m: 0, p: 0 }}>
                                    {upcomingOperations.map((operation, i) => {
                                        const isPublishedOperation = publishedOperations.find(({ flight_uuid }) => {
                                            return operation.flight_uuid === flight_uuid;
                                        });
                                        let laancBadgeInvisible = true;
                                        if (operation.laanc && operation.laanc.state) {
                                            const laanc_state = operation.laanc.state;
                                            if (laanc_state === CONSTANT.LAANC_RESCINDED_AWAITING || laanc_state === CONSTANT.LAANC_INVALID_AWAITING) {
                                                laancBadgeInvisible = false;
                                            }
                                        }
                                        const is_conforming = operation.state !== "CONTINGENT" && operation.state !== "NONCONFORMING" ? true : false;
                                        const is_editable = getOperationEditable(operation, user);
                                        return (
                                            <ListItem key={i}>
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            {operation.name}
                                                            {isDesktop === true ? (
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    sx={{
                                                                        display: "inline",
                                                                        color: GetColorFromState(operation.state, userOperationalStates)
                                                                    }}
                                                                    id={`state-${operation.volumeId}`}
                                                                >
                                                                    {` - ${operation.state}`}
                                                                </Typography>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <Typography component="span" variant="body2" sx={{ display: "inline", color: "#6b778c" }}>
                                                                {` - ${getOperationDateFromIsoString(operation.time_start)}`}
                                                            </Typography>
                                                        </>
                                                    }
                                                    secondary={
                                                        <>
                                                            {isDesktop === false ? (
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    sx={{
                                                                        display: "inline",
                                                                        color: GetColorFromState(operation.state, userOperationalStates)
                                                                    }}
                                                                >
                                                                    {operation.state}
                                                                </Typography>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {operation.laanc && operation.laanc.state ? (
                                                                <Typography component="span" variant="body2" sx={{ display: "inline" }}>
                                                                    {`${isDesktop === false ? " - " : ""}${operation.laanc.state}`}
                                                                </Typography>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {operation.conflicted === true ? (
                                                                <Typography component="span" variant="body2" sx={{ display: "inline", color: "#f44336" }}>
                                                                    {`${
                                                                        isDesktop === false || (operation.laanc && operation.laanc.state) ? " - " : ""
                                                                    }CONFLICTED`}
                                                                </Typography>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    }
                                                />
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    {isPublishedOperation !== undefined ? (
                                                        operation.state === "ACCEPTED" ? (
                                                            <Tooltip title="Activate">
                                                                <IconButton
                                                                    size="small"
                                                                    aria-label="start"
                                                                    onClick={() => handleActivateClick(operation)}
                                                                    id={`activate-${operation.volumeId}`}
                                                                >
                                                                    <PlayArrowIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title="End">
                                                                <IconButton
                                                                    size="small"
                                                                    aria-label="end"
                                                                    onClick={() => handleEndClick(operation)}
                                                                    id={`end-${operation.volumeId}`}
                                                                >
                                                                    <StopIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {laancOn === "true" && operation.faa_approval_required === true ? (
                                                        <Tooltip title="LAANC">
                                                            <Badge badgeContent={"!"} color={"error"} invisible={laancBadgeInvisible}>
                                                                <IconButton
                                                                    size="small"
                                                                    aria-label="LAANC"
                                                                    onClick={() => handleDetailsButtonClicked(operation)}
                                                                >
                                                                    <ArticleIcon />
                                                                </IconButton>
                                                            </Badge>
                                                        </Tooltip>
                                                    ) : is_editable && is_conforming ? (
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                size="small"
                                                                aria-label="settings"
                                                                onClick={() => handleEditFlightButtonClick(operation)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {operation.waypoints && operation.waypoints.length > 0 ? (
                                                        <Tooltip title="Download">
                                                            <IconButton
                                                                size="small"
                                                                aria-label="start"
                                                                onClick={() => {
                                                                    setDownloadWaypointDialogOpen(true);
                                                                    setDownloadWaypointOperation(operation);
                                                                }}
                                                                id={`download-${operation.volumeId}`}
                                                            >
                                                                <DownloadIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            size="medium"
                                                            aria-label="delete"
                                                            onClick={() => handleDeleteClick(operation)}
                                                            id={`delete-${operation.volumeId}`}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            ) : (
                                <Box sx={{ mt: 2 }}>
                                    <Divider>
                                        <TaskAltIcon fontSize="large" />
                                    </Divider>
                                    <Typography variant="h5" sx={{ my: 2, textAlign: "center" }} id="allSet">
                                        {"You're all set"}
                                    </Typography>
                                    <Typography variant="body1" sx={{ my: 2, textAlign: "center" }}>
                                        {"You don't have any published operations."}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Paper>
                </Grid>

                {/* scrollable pie charts */}
                <Grid item xs={12} md={5}>
                    <Paper sx={{ p: 2, height: 305, display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                        {Array.isArray(pieCharts) && pieCharts.length > 0 ? (
                            <>
                                <Typography variant="h6">{pieCharts[pieChartIndex] !== undefined ? pieCharts[pieChartIndex].title : ""}</Typography>
                                {pieCharts.map(({ series }, i) => {
                                    return i === pieChartIndex ? (
                                        series && series[0] && Array.isArray(series[0].data) && series[0].data.length > 0 ? (
                                            <PieChart
                                                key={i}
                                                series={series}
                                                slotProps={{
                                                    legend: {
                                                        direction: "column",
                                                        position: {
                                                            vertical: "middle",
                                                            horizontal: "right"
                                                        },
                                                        itemMarkWidth: 10,
                                                        itemMarkHeight: 10,
                                                        markGap: 5,
                                                        itemGap: 5,
                                                        labelStyle: {
                                                            fontSize: 12
                                                        }
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <PieChart
                                                key={i}
                                                series={[
                                                    {
                                                        paddingAngle: 5,
                                                        innerRadius: "85%",
                                                        outerRadius: "100%",
                                                        data: [{ label: "N/A", value: 1 }]
                                                    }
                                                ]}
                                            />
                                        )
                                    ) : (
                                        <React.Fragment key={i}></React.Fragment>
                                    );
                                })}
                                <MobileStepper
                                    variant="dots"
                                    steps={pieCharts.length}
                                    position="static"
                                    activeStep={pieChartIndex}
                                    sx={{ p: 0, backgroundColor: "transparent" }}
                                    nextButton={
                                        <IconButton
                                            size="small"
                                            onClick={() => setPieChartIndex((prev) => prev + 1)}
                                            disabled={pieChartIndex === pieCharts.length - 1}
                                        >
                                            <KeyboardArrowRight />
                                        </IconButton>
                                    }
                                    backButton={
                                        <IconButton size="small" onClick={() => setPieChartIndex((prev) => prev - 1)} disabled={pieChartIndex === 0}>
                                            <KeyboardArrowLeft />
                                        </IconButton>
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <Typography variant="h6" id="analytics">
                                    Analytics
                                </Typography>
                                <PieChart
                                    series={[
                                        {
                                            paddingAngle: 5,
                                            innerRadius: "65%",
                                            outerRadius: "80%",
                                            data: [{ label: "N/A", value: 1 }]
                                        }
                                    ]}
                                />
                            </>
                        )}
                    </Paper>
                </Grid>

                {/* flight history */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 2 }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", gap: 1 }}>
                            <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                                All Flights
                            </Typography>
                            <TextField
                                label="Search by name"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearchValueChange}
                                size="small"
                                type="text"
                            />
                        </Box>
                        {user.pilot_uuid ? (
                            <Box sx={{ height: 225, width: "100%" }}>
                                <DataGrid
                                    rows={searchedOperations}
                                    columns={columns}
                                    disableRowSeletionOnClick
                                    getRowId={(row) => row.flight_uuid}
                                    pagination
                                    slots={{ pagination: CustomPagination }}
                                    hideFooter={true}
                                />
                            </Box>
                        ) : (
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2, width: "100%" }}>
                                <Typography variant="body1">
                                    {"It appears you have not yet registered as a pilot. If you would like to do so, click below."}
                                </Typography>
                                <Box>
                                    <Button onClick={handleRegisterPilotClick} variant="contained" id="register">
                                        Register
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            {/* download waypoint dialog */}
            {downloadWaypointDialogOpen === true ? (
                <ExportFlightWaypointsMenu
                    downloadWaypointDialogOpen={downloadWaypointDialogOpen}
                    setDownloadWaypointDialogOpen={setDownloadWaypointDialogOpen}
                    downloadWaypointOperation={downloadWaypointOperation}
                    setDownloadWaypointOperation={setDownloadWaypointOperation}
                />
            ) : (
                <></>
            )}
            {/* flight history dialog */}
            {flightHistoryDialogOpen ? (
                <FlightHistoryDialog
                    flightUUID={selectedFlightHistoryFlight.flight_uuid}
                    flightHistoryOpen={flightHistoryDialogOpen}
                    setFlightHistoryOpen={setFlightHistoryDialogOpen}
                />
            ) : (
                <></>
            )}
            {/* info dialog */}
            {infoDialogOpen === true && infoDialogFlight ? (
                <FlightInfoDialog flight={infoDialogFlight} flightInfoOpen={infoDialogOpen} setFlightInfoOpen={setInfoDialogOpen} />
            ) : (
                <></>
            )}
            {/* laanc dialog */}
            {flightDetailsDialogOpen ? (
                <Suspense fallback={<></>}>
                    <LAANCDetailsDialog
                        flightDetailsVolume={flightDetailsVolume}
                        flightDetailsDialogOpen={flightDetailsDialogOpen}
                        publishedOperations={publishedOperations}
                        setFlightDetailsVolume={setFlightDetailsVolume}
                        setFlightDetailsDialogOpen={setFlightDetailsDialogOpen}
                    />
                </Suspense>
            ) : (
                <></>
            )}
        </Container>
    );
};

export default OperatorDashboard;
