import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import TextField from "@mui/material/TextField";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ErrorIcon from "@mui/icons-material/Error";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";

import { GetColorFromState, ConvertMetersToFeet } from "../../util";
import { LAANCAdvisoryTable } from "./laancAdvisoryTable";

import * as CONSTANT from "./opConstants";
import { isFurtherCoordination } from "./opUtil";

const laancStates = [
    { state: CONSTANT.LAANC_COMPLETE, color: "#5fa052" },
    { state: CONSTANT.LAANC_AUTHORIZED, color: "#5fa052" },
    { state: CONSTANT.LAANC_RESCINDED_ACK, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_INVALID_CANCEL, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_DENIED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_EXPIRED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_OPERATOR_CANCEL, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_AUTO_CANCEL, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_CLOSED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_DEAUTHORIZED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_NEVER_AUTHORIZED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_DELETED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_RESCINDED_AWAITING, color: "#E0D268" },
    { state: CONSTANT.LAANC_INVALID_AWAITING, color: "#E0D268" },
    { state: CONSTANT.LAANC_PENDING, color: "#E0D268" },
    { state: CONSTANT.LAANC_NOT_SUBMITTED, color: "#E0D268" }
];

export const LAANCWorkflowDetails = (props) => {
    const [state, setState] = useState("");
    const [tabValue, setTabValue] = useState("1");
    const [isFC, setIsFC] = useState(false);
    const [safetyJustification, setSafetyJustification] = useState("");

    useEffect(() => {
        setState(props.state);
    }, [props.state]);

    useEffect(() => {
        setIsFC(isFurtherCoordination(props.volumes));
    }, [props.volumes]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const convertAirport = (airport) => {
        if (airport === undefined) {
            return "N/A";
        } else {
            return airport;
        }
    };

    const convertRefCode = (ref_code) => {
        if (ref_code === "0") {
            return "N/A";
        } else {
            return ref_code;
        }
    };

    const LAANCVolumeGrid = () => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}
        >
            {props.volumes ? (
                props.volumes.map((laanc_volume, i) => (
                    <Grid container key={laanc_volume.id} sx={{ padding: "5px", margin: "5px 0px 5px", border: "1px, solid, grey" }}>
                        <Grid item xs={6}>
                            <Typography component="span" variant="body2">
                                Volume {i + 1}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography component="span" variant="body2" id={`reference-code-${i + 1}`}>
                                {convertRefCode(laanc_volume.id)}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title="View on Map">
                                <TravelExploreIcon
                                    color="warning"
                                    onClick={() => {
                                        props.handleLaancVolumeSelected("Volume " + (i + 1));
                                    }}
                                    key={laanc_volume.id}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="span" variant="body2">
                                Airport: {convertAirport(laanc_volume.airport)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="span" variant="body2">
                                Altitude: {ConvertMetersToFeet(laanc_volume.altitude_max_agl_m)}ft
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="span" variant="body2">
                                Type: {laanc_volume.faa_request_type.toUpperCase().replace(/_/g, " ")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="span" variant="body2">
                                State:{" "}
                            </Typography>
                            <Typography component="span" variant="body2" color={GetColorFromState(laanc_volume.state, laancStates)} id={`state-${i + 1}`}>
                                {` ${laanc_volume.state.toUpperCase().replace(/_/g, " ")}`}
                            </Typography>
                        </Grid>
                        {laanc_volume.state === CONSTANT.LAANC_RESCINDED_AWAITING ? (
                            <Grid item xs={6}>
                                <Button
                                    startIcon={<ErrorIcon color={"error"} fontSize="small" />}
                                    color="error"
                                    onClick={() => props.handleUpdateVolume(laanc_volume.id, CONSTANT.LAANC_RESCINDED_ACK)}
                                    id={`acknowledge-${i + 1}`}
                                >
                                    Acknowledge Rescind
                                </Button>
                            </Grid>
                        ) : laanc_volume.state === CONSTANT.LAANC_INVALID_AWAITING ? (
                            <Grid item xs={6}>
                                <Button
                                    startIcon={<ErrorIcon color={"error"} fontSize="small" />}
                                    color="error"
                                    onClick={() => props.handleUpdateVolume(laanc_volume.id, CONSTANT.LAANC_INVALID_CANCEL)}
                                    id={`acknowledge-${i + 1}`}
                                >
                                    Acknowledge Invalid
                                </Button>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                ))
            ) : (
                <></>
            )}
        </Box>
    );

    return (
        <Box
            sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                overflowY: "auto",
                overFlowX: "hidden",
                flexShrink: 0
            }}
            id="laancDetails"
        >
            {state === CONSTANT.LAANC_NOT_REQUIRED_STATUS ? (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <TaskAltOutlinedIcon color="success" />
                        <Typography key={99} variant="body1">
                            {"FAA LAANC Approval Not Required, click below to publish."}
                        </Typography>
                    </Box>
                    <LAANCAdvisoryTable advisories={props.advisories} isFurtherCoordination={isFC} />
                </Box>
            ) : state === CONSTANT.LAANC_REQUIRED_STATUS ? (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <TaskAltOutlinedIcon />
                        <Typography key={99} variant="body1">
                            {"FAA LAANC Approval is Required, click Proceed with LAANC."}
                        </Typography>
                    </Box>

                    <LAANCAdvisoryTable advisories={props.advisories} isFurtherCoordination={isFC} />
                </Box>
            ) : state === CONSTANT.PRECHECK_BLOCKED_STATUS ? (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <ReportProblemOutlinedIcon sx={{ color: "#fa6668" }} />
                    </Box>
                    <LAANCAdvisoryTable advisories={props.advisories} isFurtherCoordination={isFC} />
                </Box>
            ) : state === CONSTANT.LAANC_AUTO_APPROVED_STATUS ? (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <TaskAltOutlinedIcon color="success" />
                        <Typography key={99} variant="body1">
                            {"Your flight qualifies for LAANC Auto Approval.  Click the Submit To LAANC button below to proceed."}
                        </Typography>
                    </Box>
                    <LAANCAdvisoryTable advisories={props.advisories} isFurtherCoordination={isFC} />
                </Box>
            ) : state === CONSTANT.LAANC_FURTHER_COORD_STATUS ? (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <TaskAltOutlinedIcon color="warning" />
                        <Typography key={99} variant="body1" id="furtherCoordination">
                            {
                                "Your flight requires further coordination by the FAA for LAANC approval.  Enter an optional safety justification then click the Submit To LAANC button below to proceed."
                            }
                        </Typography>
                    </Box>
                    <TextField
                        onChange={(e) => {
                            props.setSafetyJustification(e.target.value);
                            setSafetyJustification(e.target.value);
                        }}
                        sx={{ mt: 0, mb: 0, width: "100%" }}
                        value={safetyJustification}
                        variant="outlined"
                        margin="dense"
                        label="Safety Justification (Optional)"
                        id="safetyJustification"
                        inputProps={{ "data-testid": "safetyJustification" }}
                    />
                    <TabContext value={tabValue}>
                        <Box sx={{ width: 1 }}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs onChange={handleTabChange} value={tabValue}>
                                    <Tab label="LAANC Volumes" value="1" />
                                    <Tab label="FAA Advisories" value="2" />
                                </Tabs>
                            </Box>
                            <TabPanel value="1" sx={{ padding: "0px !important" }}>
                                <LAANCVolumeGrid />
                            </TabPanel>
                            <TabPanel value="2" sx={{ padding: "0px !important" }}>
                                <LAANCAdvisoryTable advisories={props.advisories} isFurtherCoordination={isFC} />
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            ) : state === CONSTANT.LAANC_BLOCKED_STATUS ? (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <ReportProblemOutlinedIcon sx={{ color: "#fa6668" }} />
                        <Typography key={99} variant="body1">
                            {
                                "LAANC Blocked - Please review the blocks in the volumes above and modify your flight to continue. Your operation is not authorized to fly through LAANC at this time."
                            }
                        </Typography>
                    </Box>
                    <LAANCAdvisoryTable advisories={props.advisories} isFurtherCoordination={isFC} />
                </Box>
            ) : state === CONSTANT.FAA_APPROVAL_BLOCKED_STATUS ? (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <ReportProblemOutlinedIcon sx={{ color: "#fa6668" }} />
                        <Typography key={99} variant="body1">
                            {"Your flight has been denied LAANC Approval at this time. Users are advised to not attempt to resubmit the same request."}
                        </Typography>
                    </Box>
                    {props.volumes
                        .filter((volume) => volume.state === CONSTANT.LAANC_DENIED && volume.faa_response_msg)
                        .map((volume) => {
                            return (
                                <Grid item xs={12} key={volume.id}>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        {`${volume.name} -- ${volume.faa_response_msg}`}
                                    </Typography>
                                </Grid>
                            );
                        })}
                    <TabContext value={tabValue}>
                        <Box sx={{ width: 1 }}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs onChange={handleTabChange} value={tabValue}>
                                    <Tab label="LAANC Volumes" value="1" />
                                    <Tab label="FAA Advisories" value="2" />
                                </Tabs>
                            </Box>
                            <TabPanel value="1" sx={{ padding: "0px !important" }}>
                                <LAANCVolumeGrid />
                            </TabPanel>
                            <TabPanel value="2" sx={{ padding: "0px !important" }}>
                                <LAANCAdvisoryTable advisories={props.advisories} isFurtherCoordination={isFC} />
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            ) : state === CONSTANT.FAA_APPROVAL_PENDING_STATUS ? (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <TimerOutlinedIcon color="warning" />
                        <Typography key={99} variant="body1" sx={{ mb: 0 }} id="awaitingLAANC">
                            {"Your flight is awaiting LAANC Authorization. Please review each volume's status below."}
                        </Typography>
                    </Box>
                    <TabContext value={tabValue} sx={{ maxHeight: 35 }}>
                        <Box sx={{ width: 1 }}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs onChange={handleTabChange} value={tabValue}>
                                    <Tab label="LAANC Volumes" value="1" />
                                    <Tab label="FAA Advisories" value="2" />
                                </Tabs>
                            </Box>
                            <TabPanel value="1" sx={{ padding: "0px !important" }}>
                                <LAANCVolumeGrid />
                            </TabPanel>
                            <TabPanel value="2" sx={{ padding: "0px !important" }}>
                                <LAANCAdvisoryTable advisories={props.advisories} isFurtherCoordination={isFC} />
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            ) : state === CONSTANT.FAA_APPROVAL_CLEAR_STATUS ? (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <TaskAltOutlinedIcon color="success" />
                        <Typography key={99} variant="body1" id="authorizedLAANC">
                            {"Your flight has been authorized for LAANC. You may now publish your flight"}
                        </Typography>
                    </Box>
                    <LAANCAdvisoryTable advisories={props.advisories} isFurtherCoordination={isFC} />
                </Box>
            ) : (
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                    <TimerOutlinedIcon color="warning" />
                    <Typography variant="body1">{"Your operation is still awaiting approval"}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default LAANCWorkflowDetails;
