import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import { useUserAuth } from "../contexts/authContext";
import { useMap } from "../contexts/mapContext";
import { CustomPagination } from "../customPagination";
import { ConvertISOToDate } from "../util";

function EditToolbar(props) {
    const { setEditRow, setComponentDialogOpen } = props;

    const handleClick = () => {
        setEditRow({
            id: 0,
            name: "",
            component_uuid: "",
            truth_source: "",
            description: "",
            physical_location: "",
            manufacturer: "",
            model: "",
            is_sensor: false,
            sensor_type: "",
            lat: 0,
            lon: 0,
            radius: 0
        });
        setComponentDialogOpen(true);
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} id="createComponent" data-testid="AddRecord">
                Add Record
            </Button>
        </GridToolbarContainer>
    );
}

export function HMSComponentSettings() {
    const [components, setComponents] = useState([]);
    const [editRow, setEditRow] = useState({});
    const [componentDialogOpen, setComponentDialogOpen] = useState(false);
    const { handleFailedFetch } = useUserAuth();

    const fetchData = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        await fetch("/api/hms/components/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setComponents(data))
            .catch((err) => handleFailedFetch(err));
    };

    React.useEffect(() => {
        fetchData();
        return () => setComponents([]);
    }, []);

    const handleEditClick = (id) => {
        setEditRow(components.find((row) => row.id === id));
        setComponentDialogOpen(true);
    };

    const handleDeleteClick = (row) => {
        if (!confirm("Are you sure you want to Delete this component?")) {
            return;
        }

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" }
        };

        fetch(`/api/hms/component/delete/${row.id}`, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => fetchData())
            .catch((err) => handleFailedFetch(err));
    };

    const handleComponentDialogClose = () => {
        setComponentDialogOpen(false);
        fetchData();
    };

    const columns = [
        {
            field: "component_uuid",
            headerName: "UUID",
            flex: 1,
            minWidth: 300,
            editable: false
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 150,
            editable: false
        },

        {
            field: "truth_source",
            headerName: "Truth Source",
            minWidth: 75,
            flex: 1,
            editable: false
        },
        {
            field: "is_sensor",
            headerName: "Is Sensor",
            flex: 1,
            maxWidth: 75,
            editable: false
        },
        {
            field: "description",
            headerName: "Description",
            minWidth: 250,
            flex: 1,
            editable: false
        },
        {
            field: "physical_location",
            headerName: "Location",
            minWidth: 200,
            flex: 1,
            editable: false
        },
        {
            field: "manufacturer",
            headerName: "Manufacturer",
            minWidth: 75,
            flex: 1,
            editable: false
        },
        {
            field: "model",
            headerName: "Model",
            maxWidth: 75,
            flex: 1,
            editable: false
        },
        {
            field: "date_updated",
            headerName: "Updated",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            editable: false,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 75,
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        key={row.id}
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleEditClick(row.id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key={row.id}
                        icon={<DeleteIcon />}
                        label="Delete"
                        className="textPrimary"
                        onClick={() => handleDeleteClick(row)}
                        color="inherit"
                    />
                ];
            }
        }
    ];

    return (
        <Box sx={{ height: 567 }}>
            <DataGrid
                disableVirtualization
                rows={components}
                columns={columns}
                disableRowSeletionOnClick
                getRowId={(row) => row.id}
                pagination
                slots={{ toolbar: EditToolbar, pagination: CustomPagination }}
                slotProps={{ toolbar: { setEditRow, setComponentDialogOpen } }}
                initialState={{ pagination: { paginationModel: { pageSize: 8 } } }}
            />
            {componentDialogOpen ? (
                <ComponentDialog
                    row={editRow}
                    componentDialogOpen={componentDialogOpen}
                    handleComponentDialogClose={handleComponentDialogClose}
                    components={components}
                />
            ) : (
                <></>
            )}
        </Box>
    );
}

export function ComponentDialog(props) {
    const row = props.row;
    const { snackbar, setSnackbar } = useUserAuth();
    const { handleFailedFetch } = useUserAuth();
    const { fetchSensors } = useMap();

    const [name, setName] = useState(row.name);
    const [uuid, setUUID] = useState(row.component_uuid);
    const [truthSource, setTruthSource] = useState(row.truth_source);
    const [description, setDescription] = useState(row.description);
    const [physicalLocation, setPhysicalLocation] = useState(row.physical_location);
    const [manufacturer, setManufacturer] = useState(row.manufacturer);
    const [model, setModel] = useState(row.model);
    const [isSensor, setIsSensor] = useState(row.is_sensor ? true : false);
    const [sensorType, setSensorType] = useState(row.sensor_type);
    const [lat, setLat] = useState(row.lat);
    const [lon, setLon] = useState(row.lon);
    const [radius, setRadius] = useState(row.radius);

    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    const handleSensorChange = async () => {
        setIsSensor(!isSensor);
    };

    const handleSubmit = async () => {
        if (!name || !uuid) {
            return setSnackbar({ children: "Please fill in the below fields", severity: "error" });
        }

        if (!regexExp.test(uuid)) {
            return setSnackbar({ children: "Please enter a valid UUID", severity: "error" });
        }

        if (row.component_uuid != uuid && props.components.some((s) => s.component_uuid === uuid)) {
            return setSnackbar({ children: "Please enter a unique UUID", severity: "error" });
        }

        const newRow = {
            id: row.id,
            component_uuid: uuid,
            name: name,
            truth_source: truthSource,
            description: description,
            physical_location: physicalLocation,
            manufacturer: manufacturer,
            model: model,
            is_sensor: isSensor,
            sensor_type: sensorType,
            lat: lat,
            lon: lon,
            radius: radius
        };

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newRow)
        };
        await fetch("api/hms/component/insertupdate", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));

        fetchSensors();
        handleClose();
    };

    const handleClose = () => {
        props.handleComponentDialogClose();
    };

    return (
        <Dialog onClose={handleClose} open={props.componentDialogOpen} maxWidth="lg" width="500px">
            <DialogTitle>HIMS Component Manager</DialogTitle>
            <DialogContent sx={{ pb: 0, pt: 0 }}>
                <Box sx={{ display: "flex", gap: "10px", minWidth: 500 }}>
                    <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                        id="name"
                        label="Name"
                        fullWidth
                        error={snackbar && !name}
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                    <TextField
                        value={uuid}
                        onChange={(e) => setUUID(e.target.value)}
                        margin="normal"
                        id="uuid"
                        label="UUID"
                        fullWidth
                        error={snackbar && (!uuid || (row.component_uuid != uuid && props.components.some((s) => s.component_uuid === uuid)))}
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                    <TextField
                        value={truthSource}
                        onChange={(e) => setTruthSource(e.target.value)}
                        margin="normal"
                        id="truthsource"
                        label="Truth Source"
                        fullWidth
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                    <TextField
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        margin="normal"
                        id="description"
                        label="Description"
                        fullWidth
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                    <TextField
                        value={physicalLocation}
                        onChange={(e) => setPhysicalLocation(e.target.value)}
                        margin="normal"
                        id="physical Location"
                        label="Physical Location"
                        fullWidth
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                    <TextField
                        value={manufacturer}
                        onChange={(e) => setManufacturer(e.target.value)}
                        margin="normal"
                        id="manufacturer"
                        label="Manufacturer"
                        fullWidth
                    />
                    <TextField
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        margin="normal"
                        id="model"
                        label="Model"
                        fullWidth
                        inputProps={{ "data-testid": "model" }}
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                    <FormControlLabel control={<Checkbox checked={isSensor} onChange={handleSensorChange} id="isVisible" />} label="Is Sensor?" />
                </Box>
                {isSensor ? (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                            <TextField
                                value={lat}
                                onChange={(e) => setLat(e.target.value)}
                                margin="normal"
                                id="lat"
                                label="Lat"
                                fullWidth
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                inputProps={{ "data-testid": "lat" }}
                            />
                            <TextField
                                value={lon}
                                onChange={(e) => setLon(e.target.value)}
                                margin="normal"
                                id="lon"
                                label="Lon"
                                fullWidth
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                inputProps={{ "data-testid": "lon" }}
                            />
                        </Box>
                        <Box sx={{ display: "grid", gap: "10px", gridTemplateColumns: "1fr 1fr" }}>
                            <TextField
                                value={radius}
                                onChange={(e) => setRadius(e.target.value)}
                                id="radius"
                                label="Radius"
                                fullWidth
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                inputProps={{ "data-testid": "radius" }}
                                InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                            />
                            <FormControl>
                                <InputLabel>Sensor Type</InputLabel>
                                <Select
                                    value={sensorType}
                                    onChange={(e) => setSensorType(e.target.value)}
                                    id="sensor type"
                                    label="Sensor Type"
                                    fullWidth
                                    inputProps={{ "data-testid": "selectSensor" }}
                                >
                                    <MenuItem value="adsb" id="adsb">
                                        adsb
                                    </MenuItem>
                                    <MenuItem value="gbradar" id="gbradar">
                                        gbradar
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
                <Box sx={{ display: "flex", gap: "10px" }}></Box>
            </DialogContent>
            <DialogActions>
                <Button sx={{ mr: "auto" }} onClick={handleClose} data-testid="close" id="close">
                    Close
                </Button>
                <Button onClick={handleSubmit} data-testid="save" id="save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
