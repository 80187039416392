import dayjs from "dayjs";
import React, { useState } from "react";

import DateIcon from "@mui/icons-material/AccessTime";
import OperationIcon from "@mui/icons-material/AirplanemodeActive";
import NameIcon from "@mui/icons-material/Badge";
import ColorIcon from "@mui/icons-material/ColorLens";
import AltitudeIcon from "@mui/icons-material/Height";
import CollapseIcon from "@mui/icons-material/KeyboardArrowRight";
import RadiusIcon from "@mui/icons-material/PlaceOutlined";
import AutomaticIcon from "@mui/icons-material/PolylineOutlined";
import AlertIcon from "@mui/icons-material/PriorityHigh";
import DurationIcon from "@mui/icons-material/Timelapse";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { Collapse, Fab, MenuItem, useMediaQuery } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../contexts/authContext";
import { useMap } from "../../contexts/mapContext";
import { useSocket } from "../../contexts/socketContext";
import { MapDrawComponent } from "../../map/mapDrawComponent";
import { ConvertCoordinatesToVertices, ConvertFeetToMeters, GetTimezone, IsInvalidAltitude } from "../../util";

export default function AlertCreator() {
    const [drawType, setDrawType] = useState("Polygon");
    const [geoJson, setGeoJson] = useState(null);

    const [name, setName] = useState("");
    const [associatedOperation, setAssociatedOperation] = useState("");
    const [alertsFor, setAlertsFor] = useState("all");
    const [colorId, setColorId] = useState(7);
    const [buffer, setBuffer] = useState(100);
    const [radius, setRadius] = useState(0);
    const [minAltitudeFt, setMinAltitudeFt] = useState(0);
    const [maxAltitudeFt, setMaxAltitudeFt] = useState(400);
    const [startDate, setStartDate] = useState(dayjs().set("seconds", 0).set("milliseconds", 0));
    const [durationMin, setDurationMin] = useState(60);

    const [nameError, setNameError] = useState("");
    const [bufferError, setBufferError] = useState("");
    const [radiusError, setRadiusError] = useState("");
    const [altitudeError, setAltitudeError] = useState("");
    const [dateError, setDateError] = useState("");
    const [durationError, setDurationError] = useState("");

    const [submitting, setSubmitting] = useState(false);
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

    const { user, setSnackbar, handleFailedFetch } = useUserAuth();
    const { colors } = useMap();
    const { publishedOperations, alertVolumes } = useSocket();

    const isDesktop = useMediaQuery("(min-width:900px)");
    const navigate = useNavigate();

    const checkInputFieldsAreValid = () => {
        const prechecks = [
            {
                passed: geoJson && geoJson.length,
                snackbar: "Please create a valid alert volume to continue."
            },
            {
                passed: name && name.trim(),
                errorMessage: "Please enter a valid name.",
                setErrorMessage: setNameError
            },
            {
                passed: drawType === "Automatic" ? buffer && buffer > 0 : true,
                errorMessage: "Please enter a buffer value greater than 0.",
                setErrorMessage: setBufferError
            },
            {
                passed: drawType === "Circle" ? radius && radius > 0 : true,
                errorMessage: "Please enter a radius value greater than 0.",
                setErrorMessage: setRadiusError
            },
            {
                passed: IsInvalidAltitude(minAltitudeFt, maxAltitudeFt) === false,
                errorMessage: "Please enter a valid min and max altitude.",
                setErrorMessage: setAltitudeError
            },
            {
                passed: startDate && startDate.isValid() && parseFloat(durationMin) && parseFloat(durationMin) > 0,
                errorMessage: "Please enter a valid start date and duration.",
                setErrorMessage: setDateError
            },
            {
                passed: durationMin > 1,
                errorMessage: "Alert duration must be greater than 1 minute.",
                setErrorMessage: setDurationError
            }
        ];
        for (const precheck of prechecks) {
            if (precheck.passed) {
                if (precheck.setErrorMessage) {
                    precheck.setErrorMessage("");
                }
                continue;
            } else if (precheck.snackbar) {
                setSnackbar({ children: precheck.snackbar, severity: "error" });
            } else if (precheck.errorMessage && precheck.setErrorMessage) {
                precheck.setErrorMessage(precheck.errorMessage);
            }
            setSubmitting(false);
            return false;
        }
        return true;
    };

    const handlePublish = () => {
        const validFields = checkInputFieldsAreValid();
        if (!validFields) {
            return;
        }

        const polygons = [];
        geoJson.forEach((coords) => {
            const polygon = {
                type: "polygon",
                vertices: ConvertCoordinatesToVertices(coords)
            };
            if (drawType === "Circle") {
                polygon.lat = polygon.vertices[0].lat;
                polygon.lng = polygon.vertices[0].lng;
                polygon.radius = ConvertFeetToMeters(parseFloat(radius));
                polygon.type = "circle";
            }
            polygons.push(polygon);
        });

        const alertVolume = {
            name: name,
            manned: alertsFor,
            created_user_id: user.id,
            date_updated: new Date().toISOString(),
            date_created: new Date().toISOString(),
            updated_user_id: user.id,
            organization_id: user.organization_id,
            altitude_min_agl_m: ConvertFeetToMeters(parseFloat(minAltitudeFt)),
            altitude_max_agl_m: ConvertFeetToMeters(parseFloat(maxAltitudeFt)),
            time_start: new Date(startDate).toISOString(),
            time_end: new Date(startDate.add(durationMin, "minutes")).toISOString(),
            polygons: polygons,
            flight_name: associatedOperation.name,
            flight_uuid: associatedOperation.flight_uuid,
            flight_status: associatedOperation.state,
            color_id: colorId,
            color_name: colors.find(({ id }) => id === colorId).color,
            color_rgb: colors.find(({ id }) => id === colorId).color_rgb,
            deleted: false,
            id: 0
        };
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(alertVolume)
        };
        fetch("api/alertVolume/insertUpdate", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => handleClose())
            .catch((err) => handleFailedFetch(err));
    };

    const handleClose = () => {
        navigate("/");
    };

    return (
        <Box style={{ position: "relative", width: "100%", height: "100%", display: "flex" }}>
            <Collapse in={isDesktop || mobileDrawerOpen} orientation="horizontal">
                <Box sx={{ p: 2, width: { xs: "100%", md: "375px" }, height: "100%", overflowY: "auto" }}>
                    <Button
                        endIcon={<CollapseIcon />}
                        onClick={() => setMobileDrawerOpen(false)}
                        sx={{ display: isDesktop ? "none" : "flex", flexDirection: "row", marginLeft: "auto" }}
                    >
                        Back to map
                    </Button>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, overflowY: "auto", flexShrink: 0 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <IconField icon={NameIcon} info={["Alert Name", name, setName, nameError]} />
                            <IconField icon={OperationIcon} info={["Operation", associatedOperation, setAssociatedOperation]}>
                                {publishedOperations
                                    .filter((op) => op.state !== "PLANNING")
                                    .map((op) => (
                                        <MenuItem key={op.flight_uuid} value={op} id={op.volumeId}>
                                            {op.name}
                                        </MenuItem>
                                    ))}
                            </IconField>
                            <IconField icon={AlertIcon} info={["Alerts For", alertsFor, setAlertsFor]}>
                                {[
                                    { title: "All Aircraft", value: "all" },
                                    { title: "Manned", value: "manned" },
                                    { title: "Unmanned", value: "unmanned" }
                                ].map(({ title, value }, i) => (
                                    <MenuItem key={i} value={value} id={value}>
                                        {title}
                                    </MenuItem>
                                ))}
                            </IconField>
                            <IconField icon={ColorIcon} info={["Color", colorId, setColorId]}>
                                {colors ? (
                                    colors.map(({ id, color }) => (
                                        <MenuItem key={id} value={id} id={color.toLowerCase()}>
                                            {color}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem></MenuItem>
                                )}
                            </IconField>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Typography variant="body1">Alert Area</Typography>
                            {drawType === "Automatic" && <IconField icon={AutomaticIcon} info={["Buffer", buffer, setBuffer, bufferError]} units="ft" />}
                            {drawType === "Circle" && <IconField icon={RadiusIcon} info={["Radius", radius, setRadius, radiusError]} units="ft" />}
                            <IconWrapper icon={AltitudeIcon}>
                                <InfoField info={["Min Alt (AGL)", minAltitudeFt, setMinAltitudeFt, altitudeError]} units="ft" />
                                <InfoField info={["Max Alt (AGL)", maxAltitudeFt, setMaxAltitudeFt]} units="ft" />
                            </IconWrapper>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Typography variant="body1">Date & Time</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <IconWrapper icon={DateIcon}>
                                    <DateTimePicker
                                        label={`Start Time (${GetTimezone()}) *`}
                                        value={startDate}
                                        onChange={(value) => setStartDate(value)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                size: "small",
                                                margin: "dense",
                                                error: dateError.length > 0,
                                                helperText: dateError,
                                                inputProps: { "data-testid": "startTime" }
                                            }
                                        }}
                                    />
                                </IconWrapper>
                                <IconField icon={DurationIcon} info={["Duration", durationMin, setDurationMin, durationError]} units="mins" />
                            </LocalizationProvider>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <LoadingButton fullWidth variant="contained" loading={submitting} onClick={handlePublish} id="Publish">
                                Publish
                            </LoadingButton>
                            <Button fullWidth variant="text" onClick={handleClose}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Collapse>

            <Box sx={{ flex: 1, position: "relative", overflow: "hidden" }}>
                <MapDrawComponent
                    type="Alert"
                    publishedFlights={publishedOperations}
                    alerts={alertVolumes}
                    changeDrawType={setDrawType}
                    canEdit
                    radius={radius}
                    setRadius={setRadius}
                    automaticOperation={associatedOperation}
                    automaticBuffer={buffer}
                    colorId={colorId}
                    volumeGeojson={geoJson}
                    setVolumeGeojson={setGeoJson}
                    setMinAltitude={setMinAltitudeFt}
                    setMaxAltitude={setMaxAltitudeFt}
                    setName={setName}
                    setMobileDrawerOpen={setMobileDrawerOpen}
                />
                <Fab
                    size="medium"
                    color="primary"
                    onClick={() => setMobileDrawerOpen(true)}
                    sx={{
                        display: isDesktop || mobileDrawerOpen ? "none" : "block",
                        position: "absolute",
                        bottom: 8,
                        left: 8
                    }}
                >
                    <CollapseIcon />
                </Fab>
            </Box>
        </Box>
    );
}

const IconField = ({ icon, info, children, ...props }) => (
    <IconWrapper icon={icon}>
        <InfoField info={info} {...props}>
            {children}
        </InfoField>
    </IconWrapper>
);

const IconWrapper = ({ icon: Icon, children }) => (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
        <Icon fontSize="small" />
        {children}
    </Box>
);

const InfoField = ({ info, units, children, ...props }) => (
    <TextField
        id={info?.[0]}
        fullWidth
        size="small"
        margin="dense"
        label={info?.[0]}
        value={info?.[1]}
        onChange={(e) => info[2](e.target.value)}
        error={!!info?.[3]}
        helperText={info?.[3]}
        type={units ? "number" : undefined}
        onWheel={(e) => e.target.blur()}
        InputProps={{ endAdornment: units ? <InputAdornment position="end">{units}</InputAdornment> : undefined }}
        inputProps={{ "data-testid": info?.[0] }}
        select={!!children && Array.isArray(children)}
        {...props}
    >
        {children}
    </TextField>
);
