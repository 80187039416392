import { useEffect } from "react";

import MVT from "ol/format/MVT.js";
import VectorTileLayer from "ol/layer/VectorTile.js";
import VectorTileSource from "ol/source/VectorTile.js";

import { useEnv } from "../contexts/envContext";

import { GetLaancAirspaceStyle } from "../map/mapUtil";

const useAirspaceLayer = (map, airspace_layers) => {
    const { laancTileServerUrl } = useEnv();

    useEffect(() => {
        if (map) {
            const source = new VectorTileSource({
                format: new MVT(),
                url: `${laancTileServerUrl}/data/LAANC_AIRSPACE/{z}/{x}/{y}.pbf?time=${new Date().getTime()}`,
                minZoom: 0,
                maxZoom: 14
            });
            const layer = new VectorTileLayer({
                source: source,
                style: (feature) => GetLaancAirspaceStyle(feature, airspace_layers),
                minZoom: 0,
                properties: {
                    name: "LAANC_AIRSPACE",
                    selectable: true
                }
            });
            map.addLayer(layer);
        }
    }, [map]);
};

export default useAirspaceLayer;
