import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

import { LoadingButton } from "@mui/lab";
import { GetColorFromState } from "../../util";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomPagination } from "../../customPagination";
import { BlobProvider } from "@react-pdf/renderer";
import { ApprovalDocument } from "./approvalDocument";
import { updateLaancVolumesAfterAcknowledge, retrieveSafetyJustification } from "./opUtil";
import { LAANCAdvisoryTable } from "./laancAdvisoryTable";
import { isFurtherCoordination } from "./opUtil";

import { useUserAuth } from "../../contexts/authContext";
import { useSocket } from "../../contexts/socketContext";

import { MapDrawComponent } from "../../map/mapDrawComponent";

import * as CONSTANT from "./opConstants";

const laancStates = [
    { state: CONSTANT.LAANC_COMPLETE, color: "#5fa052" },
    { state: CONSTANT.LAANC_AUTHORIZED, color: "#5fa052" },
    { state: CONSTANT.LAANC_RESCINDED_ACK, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_INVALID_CANCEL, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_DENIED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_EXPIRED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_OPERATOR_CANCEL, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_AUTO_CANCEL, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_CLOSED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_DEAUTHORIZED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_NEVER_AUTHORIZED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_DELETED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_RESCINDED_AWAITING, color: "#E0D268" },
    { state: CONSTANT.LAANC_INVALID_AWAITING, color: "#E0D268" },
    { state: CONSTANT.LAANC_PENDING, color: "#E0D268" },
    { state: CONSTANT.LAANC_NOT_SUBMITTED, color: "#E0D268" }
];

export const LAANCDetailsDialog = ({ flightDetailsVolume, flightDetailsDialogOpen, setFlightDetailsVolume, setFlightDetailsDialogOpen, mapContainer }) => {
    const wrapperRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [printMap, setPrintMap] = useState([]);
    const [tabValue, setTabValue] = useState("1");
    const [isFC, setIsFC] = useState(false);
    const [safetyJustification, setSafetyJustification] = useState("");
    const [getScreenshot, setGetScreenshot] = useState(false);

    const { handleFailedFetch } = useUserAuth();

    const [laancVolumes, setLAANCVolumes] = useState(null);
    const [selectedLaancVolume, setSelectedLaancVolume] = useState(null);
    const { publishedOperations } = useSocket();

    const columns = [
        {
            type: "actions",
            field: "actions",
            minWidth: 50,
            maxWidth: 50,
            headerName: "",
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        key={row.id}
                        icon={<TravelExploreIcon color="warning" />}
                        label="View on Map"
                        id={`map-${row.volumeId}`}
                        onClick={() => {
                            setSelectedLaancVolume(row.name);
                        }}
                    />
                ];
            }
        },
        {
            field: "name",
            headerName: "Name",
            headerHeight: 30,
            flex: 1
        },
        {
            field: "id",
            headerName: "Reference Code",
            flex: 1,
            renderCell: (params) => {
                return <div id={`reference-code-${params.row.rowIndex}`}>{params.value}</div>;
            }
        },
        {
            field: "faa_request_type",
            headerName: "Request Type",
            flex: 1,
            renderCell: ({ formattedValue }) => {
                return <div>{formattedValue.toUpperCase().replace(/_/g, " ")}</div>;
            }
        },
        {
            field: "airport",
            headerName: "Airport",
            flex: 1
        },
        {
            field: "state",
            headerName: "State",
            flex: 1,
            renderCell: ({ formattedValue }) => {
                let fontColor = GetColorFromState(formattedValue, laancStates);
                return <div style={{ fontWeight: "bold", color: fontColor }}>{formattedValue.toUpperCase().replace(/_/g, " ")}</div>;
            }
        }
    ];

    // initialize variables
    useEffect(() => {
        setOpen(true);
    }, []);

    // listen for changes to volume and update accordingly
    useEffect(() => {
        publishedOperations.forEach((operation) => {
            if (operation.flight_uuid === flightDetailsVolume.flight_uuid) {
                const newLaancVolumes = operation.laanc.volumes.map((volume, i) => {
                    return { ...volume, name: `Volume ${i + 1}`, rowIndex: i + 1 };
                });
                setLAANCVolumes(newLaancVolumes);
                setFlightDetailsVolume(operation);
            }
        });
    }, [publishedOperations]);

    useEffect(() => {
        if (open) {
            if (flightDetailsVolume.laanc != null) {
                setIsFC(isFurtherCoordination(flightDetailsVolume.laanc.volumes));
                let safety_justification = retrieveSafetyJustification(flightDetailsVolume);
                setSafetyJustification(safety_justification);

                setGetScreenshot(true);
            }
        }
    }, [open]);

    const handleUpdateLaancState = (state) => {
        const laanc = {
            ...flightDetailsVolume.laanc,
            state: state
        };
        let operation = {
            ...flightDetailsVolume,
            laanc: laanc
        };
        operation = updateLaancVolumesAfterAcknowledge(operation);

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify([operation])
        };
        fetch("/api/op/publish", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setFlightDetailsDialogOpen(false))
            .catch((err) => handleFailedFetch(err));
    };

    const handlePrintClick = (blob) => {
        const file = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = file;
        link.download = "Approval.pdf";
        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );
        document.body.removeChild(link);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return flightDetailsVolume.laanc ? (
        <Dialog
            open={flightDetailsDialogOpen}
            onClose={() => setFlightDetailsDialogOpen(false)}
            maxWidth="lg"
            fullWidth
            container={mapContainer ? mapContainer : undefined}
        >
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, pl: 3 }}>
                <Typography variant="h5">{flightDetailsVolume.name}:</Typography>
                &nbsp;
                <Typography
                    variant="h5"
                    sx={{
                        color: laancStates.find(({ state }) => state === flightDetailsVolume.laanc.state)
                            ? laancStates.find(({ state }) => state === flightDetailsVolume.laanc.state).color
                            : "#fff"
                    }}
                >
                    {flightDetailsVolume.laanc.state.replace(/_/g, " ")}
                </Typography>
                &nbsp;
                <Typography variant="h5" sx={{ fontStyle: "italic" }}>
                    {flightDetailsVolume.block_override === undefined ? "" : "• Bypassed"}
                </Typography>
            </Box>
            <DialogContent sx={{ pb: 0 }}>
                <Box ref={wrapperRef} sx={{ position: "relative", height: "500px", mb: 1 }}>
                    <MapDrawComponent
                        type="Flight"
                        laancVolumes={laancVolumes}
                        canEdit={false}
                        selectedLAANCVolume={selectedLaancVolume}
                        setVolumeGeojson={() => {}}
                        setVolumeWaypoints={() => {}}
                        publishedFlights={[]}
                        constraints={[]}
                        getScreenshot={getScreenshot}
                        setPrintMap={setPrintMap}
                    />
                </Box>
                <Box>
                    {flightDetailsVolume.laanc.state === CONSTANT.LAANC_INVALID_AWAITING ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "3px solid grey",
                                backgroundColor: "#ffffff29",
                                gap: "50px",
                                px: "200px",
                                mt: "16px"
                            }}
                        >
                            <ReportProblemOutlinedIcon sx={{ fontSize: "32px" }} />
                            <Typography variant="body1">
                                Flight LAANC Approval is now invalid. Please acknowledge the invalid status indicating that the pilot/operator is aware that
                                their flight is no longer approved for LAANC.
                            </Typography>
                        </Box>
                    ) : flightDetailsVolume.laanc.state === CONSTANT.LAANC_RESCINDED_AWAITING ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "3px solid grey",
                                backgroundColor: "#ffffff29",
                                gap: "50px",
                                px: "200px",
                                mt: "16px"
                            }}
                        >
                            <ReportProblemOutlinedIcon sx={{ fontSize: "32px" }} />
                            <Typography variant="body1">
                                Flight LAANC Approval has been rescinded by the FAA. Please immediately acknowledge the rescind indicating that the
                                pilot/operator is aware that their FAA approval has been nullified and the flight is no longer valid.
                            </Typography>
                        </Box>
                    ) : (
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs onChange={handleTabChange} variant="scrollable" value={tabValue} scrollButtons allowScrollButtonsMobile>
                                    <Tab label="LAANC Volumes" value="1" />
                                    <Tab label="FAA Advisories" value="2" />
                                    {isFC && <Tab label="Safety Justification" value="3" />}
                                </Tabs>
                            </Box>
                            <TabPanel value="1" sx={{ padding: 1 }}>
                                <DataGrid
                                    rows={flightDetailsVolume.laanc.volumes.map((row, i) => ({ ...row, name: `Volume ${i + 1}`, rowIndex: i + 1 }))}
                                    columns={columns}
                                    getRowId={(row) => row.name}
                                    rowHeight={30}
                                    headerHeight={40}
                                    pagination
                                    slots={{ pagination: CustomPagination }}
                                    initialState={{ pagination: { paginationModel: { pageSize: 3 } } }}
                                    disableRowSelectionOnClick
                                    disableVirtualization
                                    autoHeight
                                    width={600}
                                    sx={{
                                        bgcolor: "#121212",
                                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                            display: "none"
                                        }
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value="2" sx={{ padding: 1 }}>
                                <LAANCAdvisoryTable advisories={flightDetailsVolume.advisories} isFurtherCoordination={isFC} />
                            </TabPanel>
                            <TabPanel value="3" sx={{ padding: 1 }}>
                                <Typography variant="body1">{safetyJustification}</Typography>
                            </TabPanel>
                        </TabContext>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button size="small" onClick={() => setFlightDetailsDialogOpen(false)} id="close">
                    Close
                </Button>
                {flightDetailsVolume.laanc.state === CONSTANT.LAANC_INVALID_AWAITING ? (
                    <Button size="small" onClick={() => handleUpdateLaancState(CONSTANT.LAANC_INVALID_CANCEL)} id="acknowledgeInvalid">
                        Acknowledge Invalid
                    </Button>
                ) : (
                    <></>
                )}
                {flightDetailsVolume.laanc.state === CONSTANT.LAANC_RESCINDED_AWAITING ? (
                    <Button size="small" onClick={() => handleUpdateLaancState(CONSTANT.LAANC_RESCINDED_ACK)} id="acknowledgeRescind">
                        Acknowledge Rescind
                    </Button>
                ) : (
                    <></>
                )}
                {flightDetailsVolume.laanc.state === CONSTANT.LAANC_AUTHORIZED ? (
                    <BlobProvider document={<ApprovalDocument mapBase64={printMap} flightDetailsVolume={flightDetailsVolume} />}>
                        {({ blob }) => (
                            <LoadingButton size="small" loading={printMap === null ? true : false} onClick={() => handlePrintClick(blob)} id="printApproval">
                                <span>Print Approval</span>
                            </LoadingButton>
                        )}
                    </BlobProvider>
                ) : (
                    <></>
                )}
            </DialogActions>
        </Dialog>
    ) : (
        <Backdrop open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default LAANCDetailsDialog;
