import KML from "ol/format/KML";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";

import { ConvertCmpVolumesToFeatures } from "../../map/mapUtil";
import { DateHasAlreadyPassed, IsInvalidDate } from "../../util";

import polylabel from "polylabel";

import * as CONSTANT from "./opConstants";

export function getOperationPriority(op) {
    let priority = "0 - No Priority/Personal/Hobbyist";
    if (op !== undefined && op.priority !== undefined) {
        switch (op.priority) {
            case 40:
                priority = "40 - Emergency Use";
                break;
            case 30:
                priority = "30 - Public Safety";
                break;
            case 20:
                priority = "20 - Commercial";
                break;
            case 10:
                priority = "10 - Training";
                break;
            case 0:
                priority = "0 - No Priority/Personal/Hobbyist";
                break;
            default:
                priority = `${op.priority}`;
        }
    }
    return priority;
}

export function getOperationEditable(operation, user) {
    const isExternal = operation.owner !== "cmp-ui-online" ? true : false;
    const isAdmin = user.user_role_id === 0 ? true : false;
    const isOwnOrg = operation.organization_id === user.organization_id ? true : false;
    const isOpManager = user.user_role_id === 1 ? true : false;
    const isOwnOp = user.id === operation.created_user_id ? true : false;

    if (isExternal) {
        return false;
    }
    if (isAdmin) {
        return true;
    }
    if (isOwnOrg) {
        return isOpManager ? true : isOwnOp;
    }
    return false;
}

export function handleDownloadOperations(operations, settings) {
    const features = ConvertCmpVolumesToFeatures(operations, settings);

    const labels = features.map((feature) => {
        const coordinates = feature.getGeometry().getCoordinates();
        const position = polylabel(coordinates);

        return new Feature({
            name: feature.get("name"),
            geometry: new Point([position[0], position[1]])
        });
    });
    if (Array.isArray(features) && features.length > 0) {
        const format = new KML();
        const kml = format.writeFeatures([...features, ...labels]);

        const blob = new Blob([kml], { type: "kml" });
        const file = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = file;
        link.download = "operations.kml";
        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );
        document.body.removeChild(link);
    } else {
        alert("Please select at least one operation before proceeding.");
    }
}

//checks to see if flight needs to show additional details.
export function showDetailsByState(state, laancOn) {
    if (laancOn === "false") {
        return false;
    }

    switch (state) {
        case CONSTANT.DECONFLICTION_CHECK_STATUS:
        case CONSTANT.DECONFLICTION_BLOCKED_STATUS:
        case CONSTANT.FAA_PRECHECK_STATUS:
        case CONSTANT.PRECHECK_BLOCKED_STATUS:
        case CONSTANT.PRECHECK_CLEAR_STATUS:
        case CONSTANT.LAANC_REQUIRED_STATUS:
        case CONSTANT.LAANC_NOT_REQUIRED_STATUS:
        case CONSTANT.LAANC_CHECK_STATUS:
        case CONSTANT.LAANC_BLOCKED_STATUS:
        case CONSTANT.LAANC_FURTHER_COORD_STATUS:
        case CONSTANT.LAANC_AUTO_APPROVED_STATUS:
        case CONSTANT.FAA_APPROVAL_CHECK_STATUS:
        case CONSTANT.FAA_APPROVAL_PENDING_STATUS:
        case CONSTANT.FAA_APPROVAL_CLEAR_STATUS:
        case CONSTANT.FAA_APPROVAL_BLOCKED_STATUS:
            return true;
        default:
            return false;
    }
}

//checks to see if Flight can be Published
export function canPublishByState(state, laancOn) {
    switch (state) {
        case CONSTANT.DECONFLICTION_CLEAR_STATUS:
            return laancOn === "false";
        case CONSTANT.DECONFLICTION_CHECK_STATUS:
        case CONSTANT.DECONFLICTION_BLOCKED_STATUS:
        case CONSTANT.FAA_PRECHECK_STATUS:
        case CONSTANT.PRECHECK_BLOCKED_STATUS:
        case CONSTANT.LAANC_REQUIRED_STATUS:
        case CONSTANT.LAANC_CHECK_STATUS:
        case CONSTANT.LAANC_BLOCKED_STATUS:
        case CONSTANT.FAA_APPROVAL_CHECK_STATUS:
        case CONSTANT.FAA_APPROVAL_PENDING_STATUS:
        case CONSTANT.FAA_APPROVAL_BLOCKED_STATUS:
        case CONSTANT.LAANC_FURTHER_COORD_STATUS:
        case CONSTANT.LAANC_AUTO_APPROVED_STATUS:
            return false;
        case CONSTANT.PRECHECK_CLEAR_STATUS:
        case CONSTANT.LAANC_NOT_REQUIRED_STATUS:
        case CONSTANT.FAA_APPROVAL_CLEAR_STATUS:
            return true;
        default:
            return false;
    }
}

//checks to see if the Flight can be submitted to LAANC
export function canSubmitToLAANC(state, laancOn) {
    if (laancOn === "false") {
        return false;
    }

    switch (state) {
        case CONSTANT.LAANC_FURTHER_COORD_STATUS:
        case CONSTANT.LAANC_AUTO_APPROVED_STATUS:
            return true;
        default:
            return false;
    }
}

//checks to see if the Flight can be submitted to LAANC
export function canEditFlight(state) {
    switch (state) {
        case CONSTANT.FAA_APPROVAL_CHECK_STATUS:
        case CONSTANT.FAA_APPROVAL_PENDING_STATUS:
        case CONSTANT.FAA_APPROVAL_BLOCKED_STATUS:
        case CONSTANT.FAA_APPROVAL_CLEAR_STATUS:
            return false;
        default:
            return true;
    }
}

//checks if the LAANC Services have blocked the flight.
export function isFlightBlocked(state) {
    switch (state) {
        case CONSTANT.PRECHECK_BLOCKED_STATUS:
        case CONSTANT.LAANC_REQUIRED_STATUS:
        case CONSTANT.LAANC_BLOCKED_STATUS:
        case CONSTANT.LAANC_FURTHER_COORD_STATUS:
        case CONSTANT.LAANC_AUTO_APPROVED_STATUS:
            return true;
        default:
            return false;
    }
}

function volumeHasStarted(volume) {
    const start_ms = new Date(volume.time_start).getTime();
    const now_ms = new Date().getTime();
    if (now_ms > start_ms) {
        return true;
    } else {
        return false;
    }
}

export function updateLaancVolumesAfterAcknowledge(operation) {
    if (operation && operation.laanc && operation.laanc.volumes) {
        operation.laanc.volumes.forEach((vol) => {
            if (vol.state === CONSTANT.LAANC_RESCINDED_AWAITING) {
                vol.state = CONSTANT.LAANC_RESCINDED_ACK;
            } else if (vol.state === CONSTANT.LAANC_INVALID_AWAITING) {
                //If the LAANC Volume has Started -> Invalid Awaiting goes to Closed.
                if (volumeHasStarted(vol)) {
                    vol.state = CONSTANT.LAANC_CLOSED;
                } else {
                    vol.state = CONSTANT.LAANC_INVALID_CANCEL;
                }
            } else if (vol.state !== CONSTANT.LAANC_NOT_SUBMITTED) {
                if (volumeHasStarted(vol)) {
                    vol.state = CONSTANT.LAANC_CLOSED;
                } else {
                    vol.state = CONSTANT.LAANC_OPERATOR_CANCEL;
                }
            }
        });
    }
    return operation;
}

export function userAccountHasFirstAndLastName(user) {
    if (
        Object.prototype.hasOwnProperty.call(user, "first_name") &&
        user.first_name.trim() !== "" &&
        Object.prototype.hasOwnProperty.call(user, "last_name") &&
        user.last_name.trim() !== ""
    ) {
        return true;
    } else {
        return false;
    }
}

export function isValidBase64(str) {
    const base64Pattern = /^(data:)([\w/+:.-]+)?;base64,([A-Za-z0-9+/=]+)$/;
    if (base64Pattern.test(str)) {
        return true;
    } else {
        return false;
    }
}

export function isFurtherCoordination(volumes) {
    let isFC = false;
    volumes.forEach((vol) => {
        if (vol.faa_request_type == "FURTHER_COORDINATION") {
            isFC = true;
        }
    });

    return isFC;
}

export function getWaypointsDownloadable(operation, user) {
    if (operation.waypoints && operation.waypoints.length > 0) {
        if (user.user_role === "Admin") {
            return true;
        } else if (user.user_role === "Operator") {
            return operation.created_user_id === user.id;
        } else if (user.user_role === "First Responder") {
            return false;
        } else {
            return operation.organization_id === user.organization_id;
        }
    } else {
        return false;
    }
}

export function flightIsntLongEnough(startDate, endDate) {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    return IsInvalidDate(startDate, endDate) || end - start <= 61 * 1000;
}

export function flightIsTooLong(startDate, endDate) {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    return IsInvalidDate(startDate, endDate) || (end - start) / 1000 / 60 / 60 > 12;
}

export function validateFlightTimes(flightStart, flightEnd, isPublishedFlight = false, setSnackbar = undefined) {
    let snackbar = "";
    if (IsInvalidDate(flightStart, flightEnd)) {
        snackbar = "Please enter valid times with an end time later than the start time.";
    } else if (flightIsntLongEnough(flightStart, flightEnd)) {
        snackbar = "Please enter times with a duration longer than a minute.";
    } else if (flightIsTooLong(flightStart, flightEnd)) {
        snackbar = "The operation cannot be longer than 12 hours.";
    } else if (!isPublishedFlight && DateHasAlreadyPassed(flightStart)) {
        snackbar = "The entered start time has already passed. Please select another.";
    } else {
        return true;
    }

    if (setSnackbar) {
        setSnackbar({ children: snackbar, severity: "error" });
    }
    return false;
}

export function updateSafetyJustification(operation, safetyJustification) {
    if (operation.laanc && safetyJustification !== "") {
        operation.laanc.volumes.forEach((vol) => {
            if (vol.faa_request_type === "FURTHER_COORDINATION") {
                vol.safety_justification = safetyJustification;
            }
        });
    }
}

export function retrieveSafetyJustification(operation) {
    let safety_justification = "";
    if (operation.laanc) {
        operation.laanc.volumes.forEach((vol) => {
            if (vol.faa_request_type === "FURTHER_COORDINATION") {
                safety_justification = vol.safety_justification;
            }
        });
    }
    return safety_justification;
}
