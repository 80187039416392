import React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Collapse from "@mui/material/Collapse";
import InfoIcon from "@mui/icons-material/Info";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import HmsMapPreview from "./hmsMapPreview";

import * as HMS from "./hmsUtil";
import { ConvertISOToDate } from "../util";
import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../customPagination";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ReferenceLine } from "recharts";
import { useUserAuth } from "../contexts/authContext";

export function HMSSystemStatus() {
    const [systems, setSystems] = React.useState([]);
    const [chartOpen, setChartOpen] = React.useState(false);
    const [nominalLowValue, setNominalLowValue] = React.useState(0);
    const [lowLowValue, setLowLowValue] = React.useState(0);
    const [mediumLowValue, setMediumLowValue] = React.useState(0);
    const [nominalHighValue, setNominalHighValue] = React.useState(0);
    const [lowHighValue, setLowHighValue] = React.useState(0);
    const [mediumHighValue, setMediumHighValue] = React.useState(0);
    const [selectedLatencyData, setSelectedLatencyData] = React.useState(null);
    const [faultData, setFaultData] = React.useState([]);
    const [faultOpen, setFaultOpen] = React.useState(false);
    const [historyData, setHistoryData] = React.useState([]);
    const [historyOpen, setHistoryOpen] = React.useState(false);
    const [selectedHistory, setSelectedHistory] = React.useState("");
    const [systemDetailsVisible, setSystemDetailsVisible] = React.useState(new Map());
    const [components, setComponents] = React.useState(new Map());
    const [mapSystem, setMapSystem] = React.useState(null);
    const { handleFailedFetch } = useUserAuth();

    React.useEffect(() => {
        const interval = setInterval(() => fetchData(), 30000);

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        const fetchData = async () => {
            await fetch("api/hms/system", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => setSystems(data))
                .catch((err) => handleFailedFetch(err));
        };
        fetchData();

        return () => {
            setSystems([]);
            clearInterval(interval);
        };
    }, []);

    React.useEffect(() => {
        //Set System Details Visibility
        const systemDetails = new Map();

        systems.forEach((system) => {
            const value = systemDetailsVisible.get(system.system_uuid) || false;
            systemDetails.set(system.system_uuid, value);

            //Get Component Details for Grids
            fetchComponentData(system.system_uuid);
        });

        setSystemDetailsVisible(systemDetails);
    }, [systems]);

    const fetchComponentData = async (system_uuid) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ system_uuid: system_uuid })
        };

        await fetch("api/hms/components/getbysystem", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                const newComponents = components;
                components.set(system_uuid, data);
                setComponents(new Map(newComponents));
            })
            .catch((err) => handleFailedFetch(err));
    };

    const handleToggleSystemDetails = ({ system_uuid }) => {
        const newSystemDetailsVisible = new Map();
        systemDetailsVisible.forEach((value, key) => {
            if (key === system_uuid) {
                newSystemDetailsVisible.set(key, !value);
            } else newSystemDetailsVisible.set(key, value);
        });
        setSystemDetailsVisible(newSystemDetailsVisible);
    };

    const handleChartClick = (event, cellValues) => {
        event.stopPropagation();

        //Get latency data from the database based off the selected sensor_id.
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ sensor_uuid: cellValues.row.sensor_uuid })
        };
        fetch("api/hms/latency", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setSelectedLatencyData(data))
            .catch((err) => handleFailedFetch(err));

        if (cellValues.row.sensor_type == "ADSB") {
            setNominalLowValue(-HMS.COOP_NOMINAL_VALUE);
            setNominalHighValue(HMS.COOP_NOMINAL_VALUE);
            setLowLowValue(-HMS.COOP_LOW_VALUE);
            setLowHighValue(HMS.COOP_LOW_VALUE);
            setMediumLowValue(-HMS.COOP_MEDIUM_VALUE);
            setMediumHighValue(HMS.COOP_MEDIUM_VALUE);
        } else {
            setNominalLowValue(-HMS.NONCOOP_NOMINAL_VALUE);
            setNominalHighValue(HMS.NONCOOP_NOMINAL_VALUE);
            setLowLowValue(-HMS.NONCOOP_LOW_VALUE);
            setLowHighValue(HMS.NONCOOP_LOW_VALUE);
            setMediumLowValue(-HMS.NONCOOP_MEDIUM_VALUE);
            setMediumHighValue(HMS.NONCOOP_MEDIUM_VALUE);
        }

        setChartOpen(true);
    };

    const handleFaultClick = (event, cellValues) => {
        event.stopPropagation();
        setFaultData(cellValues.row.faults);
        setFaultOpen(true);
    };

    const handleCloseChart = () => {
        setChartOpen(false);
    };

    const handleCloseFault = () => {
        setFaultOpen(false);
    };

    const handleCloseHistory = () => {
        setHistoryOpen(false);
    };

    const formatXAxis = (tickItem) => {
        return moment.unix(tickItem).format("HH:mm");
    };

    /* istanbul ignore next */
    const handleOpenMap = (system) => {
        if (system !== null) {
            setMapSystem(system);
        }
    };

    const handleOpenSystemStatusHistory = (system_uuid, system_name) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ system_uuid: system_uuid })
        };
        fetch("api/hms/system/history", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                let lastDate = new Date();
                data.forEach((row) => {
                    row.time = moment(lastDate).diff(moment(ConvertISOToDate(row.date_reported), "MM/DD/YYYY HH:mm:ss Z"), "minutes");
                    lastDate = moment(ConvertISOToDate(row.date_reported), "MM/DD/YYYY HH:mm:ss Z");
                });
                setHistoryData(data);
            })
            .catch((err) => handleFailedFetch(err));

        setSelectedHistory(system_name);
        setHistoryOpen(true);
    };

    const faultColumns = [
        {
            field: "severity",
            headerName: "Severity",
            minWidth: 100,
            maxWidth: 100,
            flex: 1
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 150
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            minWidth: 300
        }
    ];

    const historyColumns = [
        {
            field: "date_reported",
            headerName: "Date Changed",
            minWidth: 200,
            maxWidth: 200,
            flex: 1
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 150
        },
        {
            field: "time",
            headerName: "Min in Status",
            maxWidth: 100,
            flex: 1
        }
    ];

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 150
        },
        {
            field: "sensor_state",
            headerName: "Status",
            flex: 1,
            minWidth: 100,
            editable: false,
            renderCell: (cellValues) => {
                let color = HMS.DEFAULT_COLOR;
                if (cellValues.row.sensor_state == "FAULTED") color = HMS.FAILURE_COLOR;
                if (cellValues.row.sensor_state == "ONLINE") color = HMS.ONLINE_COLOR;
                if (cellValues.row.sensor_state == "STARTING_UP") color = HMS.START_UP_COLOR;
                return (
                    <div
                        style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: color
                        }}
                    >
                        {cellValues.row.sensor_state}
                    </div>
                );
            }
        },
        {
            field: "severity",
            headerName: "Fault State",
            minWidth: 100,
            flex: 1
        },
        {
            field: "info",
            headerName: "",
            minWidth: 40,
            maxWidth: 40,
            flex: 1,
            align: "center",
            display: "flex",
            renderCell: (cellValues) => {
                const hasFaults = cellValues.row.faults != null ? cellValues.row.faults.length > 0 : false;
                if (hasFaults) {
                    return (
                        <IconButton size="medium" onClick={(event) => handleFaultClick(event, cellValues)}>
                            <InfoIcon />
                        </IconButton>
                    );
                }
            }
        },
        {
            field: "latency_info",
            headerName: "",
            minWidth: 50,
            maxWidth: 50,
            flex: 1,
            align: "center",
            display: "flex",
            renderCell: (cellValues) => {
                const lessThanOneHourAgo = moment(ConvertISOToDate(cellValues.row.latency_date), "MM/DD/YYYY HH:mm:ss Z").isAfter(
                    moment().subtract(1, "hours")
                );
                if (lessThanOneHourAgo) {
                    return (
                        <IconButton size="medium" onClick={(event) => handleChartClick(event, cellValues)}>
                            <ShowChartIcon />
                        </IconButton>
                    );
                }
            }
        },
        {
            field: "date_reported",
            headerName: "Date Reported",
            minWidth: 200,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        }
    ];

    return (
        <>
            <Grid item xs={16} sx={{ maxWidth: "1400px !important", pt: 3, px: 3, width: "100%" }}>
                <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1, pb: 2 }}>
                    Health Integrity Monitoring
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {systems.map((system, i) => {
                            const statusColor = HMS.getSystemColorFromType(system.status);
                            const detailsVisible = systemDetailsVisible.get(system.system_uuid);

                            let numberOfComponents = 0;
                            if (system.hms_components) {
                                numberOfComponents = system.hms_components.length;
                            }
                            return i % 2 === 0 ? (
                                <Card key={system.system_uuid} sx={{ overflow: "unset" }}>
                                    <CardHeader
                                        title={system.name.toUpperCase().replace(/_/g, " ")}
                                        sx={{ pb: 1, borderBottom: 1, backgroundColor: statusColor }}
                                    />
                                    <Box sx={{ my: 1, display: "flex", flexDirection: "column", gap: 1 }}>
                                        <CardContent sx={{ py: 1 }}>
                                            <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <b>Status: </b>
                                                <span style={{ color: statusColor }}>{system.status}</span>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <b>Date Reported: </b>
                                                <span>{ConvertISOToDate(system.date_reported)}</span>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <b># of Components: </b>
                                                <span>{numberOfComponents}</span>
                                            </Typography>
                                            <Button onClick={() => handleOpenSystemStatusHistory(system.system_uuid, system.name)} sx={{ p: "0px !important" }}>
                                                View System History
                                            </Button>
                                            {system.status_areas ? (
                                                <>
                                                    <br />
                                                    <Button onClick={() => handleOpenMap(system)} sx={{ p: "0px !important" }}>
                                                        View System Status on Map
                                                    </Button>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </CardContent>
                                        <CardActions sx={{ gap: 1, padding: "0px !important" }}>
                                            <IconButton onClick={() => handleToggleSystemDetails(system)} sx={{ ml: "auto !important" }}>
                                                {detailsVisible ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </CardActions>
                                    </Box>
                                    <Collapse in={detailsVisible} timeout="auto" unmountOnExit>
                                        <CardContent sx={{ p: "8px !important", borderTop: 1, display: "grid", gridTemplateColumns: "1fr", gap: 1 }}>
                                            <Box
                                                sx={{
                                                    "& .high": { backgroundColor: HMS.FAILURE_COLOR },
                                                    "& .medium": { backgroundColor: HMS.DEGRADED_OUTAGE_COLOR },
                                                    "& .low": { backgroundColor: HMS.DEGRADED_COLOR },
                                                    "& .nominal": { backgroundColor: HMS.ONLINE_COLOR }
                                                }}
                                            >
                                                <DataGrid
                                                    disableVirtualization
                                                    rows={components.get(system.system_uuid) ? components.get(system.system_uuid) : []}
                                                    columns={columns}
                                                    disableRowSeletionOnClick
                                                    autoHeight
                                                    rowHeight={30}
                                                    headerHeight={40}
                                                    getRowId={(row) => row.sensor_uuid}
                                                    pagination
                                                    slots={{ pagination: CustomPagination }}
                                                    initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                                                    getCellClassName={(params) => {
                                                        if (params.field === "severity") {
                                                            return params.value.toLowerCase();
                                                        }
                                                        return "";
                                                    }}
                                                />
                                            </Box>
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            ) : (
                                <React.Fragment key={system.system_uuid}></React.Fragment>
                            );
                        })}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {systems.map((system, i) => {
                            const statusColor = HMS.getSystemColorFromType(system.status);
                            const detailsVisible = systemDetailsVisible.get(system.system_uuid);

                            let numberOfComponents = 0;
                            if (system.hms_components) {
                                numberOfComponents = system.hms_components.length;
                            }
                            return i % 2 === 1 ? (
                                <Card key={system.system_uuid} sx={{ overflow: "unset" }}>
                                    <CardHeader
                                        title={system.name.toUpperCase().replace(/_/g, " ")}
                                        sx={{ pb: 1, borderBottom: 1, backgroundColor: statusColor }}
                                    />
                                    <Box sx={{ my: 1, display: "flex", flexDirection: "column", gap: 1 }}>
                                        <CardContent sx={{ py: 1 }}>
                                            <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <b>Status: </b>
                                                <span style={{ color: statusColor }}>{system.status}</span>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <b>Date Reported: </b>
                                                <span>{ConvertISOToDate(system.date_reported)}</span>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <b># of Components: </b>
                                                <span>{numberOfComponents}</span>
                                            </Typography>
                                            <Button onClick={() => handleOpenSystemStatusHistory(system.system_uuid, system.name)} sx={{ p: "0px !important" }}>
                                                View System History
                                            </Button>
                                            {system.status_areas ? (
                                                <>
                                                    <br />
                                                    <Button onClick={() => handleOpenMap(system)} sx={{ p: "0px !important" }}>
                                                        View System Status on Map
                                                    </Button>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </CardContent>
                                        <CardActions sx={{ gap: 1, padding: "0px !important" }}>
                                            <IconButton onClick={() => handleToggleSystemDetails(system)} sx={{ ml: "auto !important" }}>
                                                {detailsVisible ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </CardActions>
                                    </Box>
                                    <Collapse in={detailsVisible} timeout="auto" unmountOnExit>
                                        <CardContent sx={{ p: "8px !important", borderTop: 1, display: "grid", gridTemplateColumns: "1fr", gap: 1 }}>
                                            <Box
                                                sx={{
                                                    "& .high": { backgroundColor: HMS.FAILURE_COLOR },
                                                    "& .medium": { backgroundColor: HMS.DEGRADED_OUTAGE_COLOR },
                                                    "& .low": { backgroundColor: HMS.DEGRADED_COLOR },
                                                    "& .nominal": { backgroundColor: HMS.ONLINE_COLOR }
                                                }}
                                            >
                                                <DataGrid
                                                    disableVirtualization
                                                    rows={components.get(system.system_uuid) ? components.get(system.system_uuid) : []}
                                                    columns={columns}
                                                    disableRowSeletionOnClick
                                                    autoHeight
                                                    rowHeight={30}
                                                    headerHeight={40}
                                                    getRowId={(row) => row.sensor_uuid}
                                                    pagination
                                                    slots={{ pagination: CustomPagination }}
                                                    initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                                                    getCellClassName={(params) => {
                                                        if (params.field === "severity") {
                                                            return params.value.toLowerCase();
                                                        }
                                                        return "";
                                                    }}
                                                />
                                            </Box>
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            ) : (
                                <React.Fragment key={system.system_uuid}></React.Fragment>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>

            {mapSystem !== null ? <HmsMapPreview mapSystem={mapSystem} setMapSystem={setMapSystem} /> : <></>}

            <Dialog open={chartOpen} onClose={handleCloseChart}>
                <DialogContent sx={{ minWidth: "350px", pb: 0 }}>
                    <Box sx={{ minWidth: 350, marginTop: "5px" }}>
                        <LineChart
                            width={500}
                            height={300}
                            data={selectedLatencyData}
                            margin={{
                                top: 20,
                                right: 50,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="epoc_time" type="number" domain={["auto", "auto"]} tickFormatter={formatXAxis} />
                            <YAxis dataKey="average" />
                            <Legend />
                            <ReferenceLine y={nominalLowValue} stroke="green" label="Nominal" />
                            <ReferenceLine y={nominalHighValue} stroke="green" label="Nominal" />
                            <ReferenceLine y={lowLowValue} stroke="yellow" label="Low" />
                            <ReferenceLine y={lowHighValue} stroke="yellow" label="Low" />
                            <ReferenceLine y={mediumLowValue} stroke="orange" label="Medium" />
                            <ReferenceLine y={mediumHighValue} stroke="orange" label="Medium" />
                            <Line type="monotone" dataKey="average" stroke="#8884d8" />
                        </LineChart>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChart}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={faultOpen} onClose={handleCloseFault} maxWidth="md">
                <DialogContent sx={{ minWidth: "600px", pb: 0 }}>
                    <Box
                        sx={{
                            minWidth: 600,
                            marginTop: "5px",
                            "& .high": { backgroundColor: HMS.FAILURE_COLOR },
                            "& .medium": { backgroundColor: HMS.DEGRADED_OUTAGE_COLOR },
                            "& .low": { backgroundColor: HMS.DEGRADED_COLOR },
                            "& .nominal": { backgroundColor: HMS.ONLINE_COLOR }
                        }}
                    >
                        <DataGrid
                            disableVirtualization
                            autoHeight
                            rows={faultData}
                            columns={faultColumns}
                            disableRowSeletionOnClick
                            getRowId={(row) => row.name}
                            pagination
                            slots={{ pagination: CustomPagination }}
                            initialState={{ pagination: { paginationModel: { pageSize: 8 } } }}
                            getCellClassName={(params) => {
                                if (params.field === "severity") {
                                    return params.value.toLowerCase();
                                }
                                return "";
                            }}
                            sx={{
                                bgcolor: "#121212"
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFault}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={historyOpen} onClose={handleCloseHistory} maxWidth="md">
                <DialogTitle>{selectedHistory.toUpperCase().replace(/_/g, " ") + " HISTORY"}</DialogTitle>
                <DialogContent sx={{ minWidth: "600px", pb: 0 }}>
                    <Box
                        sx={{
                            minWidth: 600,
                            marginTop: "5px",
                            "& .failure": { backgroundColor: HMS.FAILURE_COLOR },
                            "& .degraded_outage": { backgroundColor: HMS.DEGRADED_OUTAGE_COLOR },
                            "& .degraded": { backgroundColor: HMS.DEGRADED_COLOR },
                            "& .nominal": { backgroundColor: HMS.ONLINE_COLOR }
                        }}
                    >
                        <DataGrid
                            disableVirtualization
                            autoHeight
                            rows={historyData}
                            columns={historyColumns}
                            disableRowSeletionOnClick
                            rowHeight={30}
                            headerHeight={40}
                            getRowId={(row) => row.date_reported}
                            pagination
                            slots={{ pagination: CustomPagination }}
                            initialState={{ pagination: { paginationModel: { pageSize: 22 } } }}
                            getCellClassName={(params) => {
                                if (params.field === "status") {
                                    return params.value.toLowerCase();
                                }
                                return "";
                            }}
                            sx={{
                                bgcolor: "#121212"
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseHistory}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
