import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { ConvertFeetToMeters, ConvertMetersToFeet, DateHasAlreadyPassed, getConstraintDurationFromInput, GetTimezone } from "../../util";

import { useUserAuth } from "../../contexts/authContext";

export const ConstraintEdit = (props) => {
    const ADVISORY_TYPE = "ADVISORY";
    const TRAINING_TYPE = "TRAINING";
    const COMMERCIAL_TYPE = "COMMERCIAL";
    const PUBLIC_SAFETY_TYPE = "PUBLIC_SAFETY";
    const EMERGENCY_TYPE = "EMERGENCY";
    const RESTRICTED_TYPE = "RESTRICTED";

    const [editName, setEditName] = useState("");
    const [editEnd, setEditEnd] = useState(dayjs());
    const [editStart, setEditStart] = useState(dayjs());
    const [editAltitude, setEditAltitude] = useState(0);
    const [editDuration, setEditDuration] = useState(0);
    const [editPriority, setEditPriority] = useState(0);
    const [editType, setEditType] = useState(ADVISORY_TYPE);
    const [publishedConstraint, setPublishedConstraint] = useState(false);

    const { handleFailedFetch } = useUserAuth();

    useEffect(() => {
        const name = props.constraintEditEntity.constraint.name;
        setEditName(name);

        const constraint_type = props.constraintEditEntity.constraint.type;
        setEditType(constraint_type);

        const start_time = new Date(props.constraintEditEntity.constraint.time_start).getTime();
        setEditStart(start_time);

        const end_time = new Date(props.constraintEditEntity.constraint.time_end).getTime();
        setEditEnd(end_time);

        const duration_min = Math.floor((end_time - start_time) / 1000 / 60);
        setEditDuration(duration_min);

        const altitude_agl_ft = ConvertMetersToFeet(props.constraintEditEntity.constraint.volumes[0].altitude_max_agl_m);
        setEditAltitude(altitude_agl_ft);

        const is_published = props.constraintEditEntity.constraint.state === "ACCEPTED";
        setPublishedConstraint(is_published);
    }, []);

    // Fires if user hits save
    const handleUpdateConstraint = () => {
        if (!editName) {
            props.setSnackbar({ children: "Please enter a valid name", severity: "error" });
            return false;
        }
        if (!publishedConstraint && DateHasAlreadyPassed(editStart)) {
            props.setSnackbar({ children: "Start date has already passed", severity: "error" });
            return false;
        }
        if (isNaN(parseFloat(editAltitude)) || parseFloat(editAltitude) <= 0) {
            props.setSnackbar({ children: "Please enter a valid altitude", severity: "error" });
            return false;
        }
        if (isNaN(parseFloat(editDuration)) || parseFloat(editDuration) <= 0) {
            props.setSnackbar({ children: "Please enter a valid duration", severity: "error" });
            return false;
        }
        const constraint = props.constraintEditEntity.constraint;
        constraint.name = editName;
        constraint.time_end = editEnd;
        constraint.time_start = editStart;
        constraint.altitude = ConvertFeetToMeters(parseFloat(editAltitude));
        constraint.version = parseInt(constraint.version) + 1;
        constraint.type = editType;
        constraint.priority = editPriority;
        constraint.volumes.forEach((vol) => {
            vol.altitude_max_agl_m = ConvertFeetToMeters(parseFloat(editAltitude));
        });
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        fetch("api/constraint/update", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                props.setSnackbar({ children: "Constraint successfully updated", severity: "success" });
                props.setConstraintEditDialogOpen(false);
            })
            .catch((err) => handleFailedFetch(err));
    };

    // Fires if delete button clicked
    const handleDeleteConstraint = () => {
        if (!confirm("Are you sure you want to delete this constraint?")) return;

        const constraint = props.constraintEditEntity.constraint;

        //Put Operations data over FETCH request
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        fetch("/api/constraint/delete", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                props.setSnackbar({ children: "Constraint successfully deleted", severity: "success" });
                props.setConstraintEditDialogOpen(false);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const handleTypeChange = (e) => {
        const new_type = e.target.value;
        setEditType(new_type);

        if (new_type === RESTRICTED_TYPE) {
            setEditPriority(1000);
        } else if (new_type === EMERGENCY_TYPE) {
            setEditPriority(40);
        } else if (new_type === PUBLIC_SAFETY_TYPE) {
            setEditPriority(30);
        } else if (new_type === COMMERCIAL_TYPE) {
            setEditPriority(20);
        } else if (new_type === TRAINING_TYPE) {
            setEditPriority(10);
        } else if (new_type === ADVISORY_TYPE) {
            setEditPriority(0);
        }
    };
    const handleStartDateChange = (new_start_date) => {
        setEditStart(new_start_date);

        const duration_num_min = parseFloat(editDuration);
        const is_good_duration = !isNaN(duration_num_min) && duration_num_min > 0;

        if (dayjs(new_start_date).isValid() === true && is_good_duration) {
            const new_end_date = dayjs(new_start_date).add(duration_num_min, "minutes");
            setEditEnd(new_end_date);
        }
    };
    const handleDurationChange = (e) => {
        const new_duration_min = getConstraintDurationFromInput(e.target.value);
        if (new_duration_min !== undefined) {
            setEditDuration(new_duration_min);
        }
        const is_good_duration = !isNaN(new_duration_min) && new_duration_min > 0;
        if (is_good_duration && dayjs(editStart).isValid() === true) {
            const new_end_date = dayjs(editStart).add(new_duration_min, "minutes");
            setEditEnd(new_end_date);
        }
    };
    return (
        <Dialog
            open={props.constraintEditDialogOpen}
            onContextMenu={(e) => e.preventDefault()}
            fullWidth={true}
            maxWidth={"sm"}
            container={props.mapContainer ? props.mapContainer : undefined}
        >
            <DialogTitle>Edit Constraint</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, mb: 2, pt: 1 }}>
                    <TextField
                        inputProps={{ "data-testid": "editName" }}
                        onChange={(e) => setEditName(e.target.value)}
                        error={props.snackbar && !editName}
                        variant="outlined"
                        value={editName}
                        id="nameInput"
                        label="Name"
                        sx={{ flex: 1 }}
                    />

                    <TextField
                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                        inputProps={{ "data-testid": "editAltitude" }}
                        onChange={(e) => setEditAltitude(e.target.value)}
                        error={props.snackbar && (isNaN(parseFloat(editAltitude)) || parseFloat(editAltitude) <= 0)}
                        variant="outlined"
                        id="editAltitude"
                        label="Altitude (AGL)"
                        value={editAltitude}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        sx={{ flex: 1 }}
                    />

                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="select-type-label">Type</InputLabel>
                        <Select
                            value={editType}
                            id="selectType"
                            labelId="select-type-label"
                            label="Type"
                            onChange={handleTypeChange}
                            inputProps={{ "data-testid": "selectType" }}
                        >
                            <MenuItem value={ADVISORY_TYPE} id="advisory">
                                Advisory
                            </MenuItem>
                            <MenuItem value={TRAINING_TYPE} id="training">
                                Training
                            </MenuItem>
                            <MenuItem value={COMMERCIAL_TYPE} id="commercial">
                                Commercial
                            </MenuItem>
                            <MenuItem value={PUBLIC_SAFETY_TYPE} id="safety">
                                Public Safety
                            </MenuItem>
                            <MenuItem value={EMERGENCY_TYPE} id="emergency">
                                Emergency
                            </MenuItem>
                            <MenuItem value={RESTRICTED_TYPE} id="restricted">
                                Restricted
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                    <Box sx={{ flex: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label={`Start Time (${GetTimezone()}) *`}
                                value={dayjs(editStart)}
                                onChange={handleStartDateChange}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: props.snackbar && !publishedConstraint && DateHasAlreadyPassed(editStart),
                                        inputProps: { "data-testid": "startTime" }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Box>

                    <TextField
                        error={props.snackbar && (isNaN(parseFloat(editDuration)) || parseFloat(editDuration) <= 0)}
                        inputProps={{ "data-testid": "editDuration" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment> }}
                        variant="outlined"
                        label="Duration"
                        onChange={handleDurationChange}
                        value={editDuration}
                        sx={{ flex: 1 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteConstraint} data-testid="delete" size="small" sx={{ mr: "auto" }}>
                    Delete
                </Button>
                <Button onClick={() => props.setConstraintEditDialogOpen(false)} data-testid="cancel" size="small">
                    Cancel
                </Button>
                <Button onClick={handleUpdateConstraint} data-testid="accept" size="small">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
