import React, { useState } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { ConvertFeetToMeters, DateHasAlreadyPassed, GetTimezone } from "../../util";

import { useUserAuth } from "../../contexts/authContext";

export const ConstraintDraw = (props) => {
    const ADVISORY_TYPE = "ADVISORY";
    const TRAINING_TYPE = "TRAINING";
    const COMMERCIAL_TYPE = "COMMERCIAL";
    const PUBLIC_SAFETY_TYPE = "PUBLIC_SAFETY";
    const EMERGENCY_TYPE = "EMERGENCY";
    const RESTRICTED_TYPE = "RESTRICTED";

    const [createName, setCreateName] = useState("");
    const [createStart, setCreateStart] = useState(dayjs().add("10", "minutes").set("seconds", 0));
    const [createEnd, setCreateEnd] = useState(dayjs().add("70", "minutes").set("seconds", 0));

    const [createAltitude, setCreateAltitude] = useState(0);
    const [createDuration, setCreateDuration] = useState(60);

    const [createType, setCreateType] = useState(ADVISORY_TYPE);
    const [createPriority, setCreatePriority] = useState(0);

    const { user, handleFailedFetch } = useUserAuth();

    const handleTypeChange = (e) => {
        const new_type = e.target.value;
        setCreateType(new_type);

        if (new_type === RESTRICTED_TYPE) {
            setCreatePriority(1000);
        } else if (new_type === EMERGENCY_TYPE) {
            setCreatePriority(40);
        } else if (new_type === PUBLIC_SAFETY_TYPE) {
            setCreatePriority(30);
        } else if (new_type === COMMERCIAL_TYPE) {
            setCreatePriority(20);
        } else if (new_type === TRAINING_TYPE) {
            setCreatePriority(10);
        } else if (new_type === ADVISORY_TYPE) {
            setCreatePriority(0);
        }
    };
    const handleStartDateChange = (new_start_date) => {
        setCreateStart(new_start_date);

        const duration_num_min = parseFloat(createDuration);
        const is_good_duration = !isNaN(duration_num_min) && duration_num_min > 0;

        if (dayjs(new_start_date).isValid() === true && is_good_duration) {
            const new_end_date = dayjs(new_start_date).add(duration_num_min, "minutes");
            setCreateEnd(new_end_date);
        }
    };
    const handleDurationChange = (e) => {
        const new_duration_min = e.target.value;
        setCreateDuration(new_duration_min);

        const is_good_duration = !isNaN(new_duration_min) && new_duration_min > 0;
        if (is_good_duration && dayjs(createStart).isValid() === true) {
            const new_end_date = dayjs(createStart).add(new_duration_min, "minutes");
            setCreateEnd(new_end_date);
        }
    };
    const handlePublish = async () => {
        const bad_name = !createName;
        const bad_start_date = dayjs().isValid() === false || DateHasAlreadyPassed(createStart);
        const bad_altitude = isNaN(parseFloat(createAltitude)) || parseFloat(createAltitude) <= 0;
        const bad_duration = isNaN(parseFloat(createDuration)) || parseFloat(createDuration) <= 0;

        if (bad_name || bad_start_date || bad_altitude || bad_duration) {
            props.setSnackbar({ children: "Please fill out the required fields.", severity: "error" });
            return false;
        }
        const altitude_num_ft = parseFloat(createAltitude);
        const altitude_num_m = ConvertFeetToMeters(altitude_num_ft);

        const constraint = {
            name: createName,
            type: createType,
            altitude: altitude_num_m,
            organization: user.organization,
            organization_id: user.organization_id,
            created_user_id: user.id,
            priority: createPriority,
            time_start: createStart,
            time_end: createEnd,
            volumes: props.constraintCreateVertices,
            state: "PLANNING",
            version: 1
        };
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        await fetch("api/constraint/planning", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                props.setSnackbar({ children: "Constraint successfully created", severity: "success" });
                props.setConstraintCreateDialogOpen(false);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const handleCancel = () => {
        props.setConstraintCreateDialogOpen(false);
    };
    return (
        <Dialog
            open={props.constraintCreateDialogOpen}
            onContextMenu={(e) => e.preventDefault()}
            fullWidth={true}
            maxWidth={"sm"}
            container={props.mapContainer ? props.mapContainer : undefined}
        >
            <DialogTitle>Create Constraint</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, mb: 2, pt: 1 }}>
                    <TextField
                        inputProps={{ "data-testid": "createName" }}
                        onChange={(e) => setCreateName(e.target.value)}
                        error={props.snackbar && !createName}
                        variant="outlined"
                        id="nameInput"
                        label="Name"
                        value={createName}
                        sx={{ flex: 1 }}
                    />

                    <TextField
                        inputProps={{ "data-testid": "inputAltitude" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                        onChange={(e) => setCreateAltitude(e.target.value)}
                        error={props.snackbar && (isNaN(parseFloat(createAltitude)) || parseFloat(createAltitude) <= 0)}
                        variant="outlined"
                        id="createAltitude"
                        label="Altitude (AGL)"
                        value={createAltitude}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        sx={{ flex: 1 }}
                    />

                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="select-priority-air">Type</InputLabel>
                        <Select value={createType} id="selectType" labelId="select-label" label="Type" onChange={handleTypeChange}>
                            <MenuItem value={ADVISORY_TYPE}>Advisory</MenuItem>
                            <MenuItem value={TRAINING_TYPE}>Training</MenuItem>
                            <MenuItem value={COMMERCIAL_TYPE}>Commercial</MenuItem>
                            <MenuItem value={PUBLIC_SAFETY_TYPE}>Public Safety</MenuItem>
                            <MenuItem value={EMERGENCY_TYPE}>Emergency</MenuItem>
                            <MenuItem value={RESTRICTED_TYPE}>Restricted</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                    <Box sx={{ flex: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label={`Start Time (${GetTimezone()}) *`}
                                value={createStart}
                                onChange={handleStartDateChange}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: props.snackbar && DateHasAlreadyPassed(createStart),
                                        inputProps: { "data-testid": "start-date-picker" }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Box>

                    <TextField
                        error={props.snackbar && (isNaN(parseFloat(createDuration)) || parseFloat(createDuration) <= 0)}
                        InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment> }}
                        variant="outlined"
                        id="durationInput"
                        label="Duration"
                        onChange={handleDurationChange}
                        value={createDuration}
                        sx={{ flex: 1 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} size="small">
                    Cancel
                </Button>
                <Button onClick={handlePublish} data-testid="accept" size="small">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};
