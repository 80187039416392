import React, { Suspense, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import LayersIcon from "@mui/icons-material/Layers";
import SettingsIcon from "@mui/icons-material/Settings";
import StyleIcon from "@mui/icons-material/Style";
import MapIcon from "@mui/icons-material/Map";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

import Feature from "ol/Feature";
import Circle from "ol/geom/Circle";
import Draw from "ol/interaction/Draw";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";

import LAANCDetailsDialog from "../manager/operations/laancDetailsDialog";

import { ConversationDialog } from "../chat/conversationDialog";
import { useUserAuth } from "../contexts/authContext";
import { useMap } from "../contexts/mapContext";
import { useSocket } from "../contexts/socketContext";

import { ConstraintCircleCreateDialog } from "../manager/constraints/constraintCircleCreateDialog";
import { ConstraintDraw } from "../manager/constraints/constraintDraw";
import FlightCreator from "../manager/operations/flightCreator";
import { EntityEditDialog } from "./entityEditDialog";
import { ConstraintEdit } from "../manager/constraints/constraintEdit";

import { MapFlightCards } from "../map/mapFlightCards";
import { MapSettingsDropdown } from "../map/mapSettingsDropdown";
import { MapLayersDropdown } from "./mapLayersDropdown";
import { EntityWatchToolbar } from "./entityWatchToolbar";
import MapTileDropdown from "./mapTileDropdown";
import MapInfoCard from "./mapInfoCard";
import MapFullScreen from "./mapFullScreen";

import {
    getGeometryStyleFromColor,
    getGeometryStyleFromFeatureAndSettings,
    getHistLineCoordinatesFromTrack,
    getPredLineCoordinatesFromTrackAndSettings,
    getZeroConflictionLineStyleFromZeroConflictMessage,
    ConvertCmpVolumesToFeatures,
    createZeroConflictionLineFeature,
    updateFeaturesZeroConflictionAlerts,
    GetFeature,
    GetFeatures,
    GetLayer,
    GetSource,
    JumpToFeatures,
    GetAllFeaturesWithId,
    updateZeroConflictLineFeature,
    GetLaancAirspaceStyle,
    getAlertVolumeConflictionAlertStyle,
    getAsdFeatureStyleFromLayersAndSettings,
    getLabelTextFromFeatureAndUser
} from "../map/mapUtil";

import useOpenLayersMap from "../hooks/useOpenLayersMap";
import { useLocalStorage } from "../hooks/useLocalStorage";
import useAirspaceLayer from "../hooks/useAirspaceLayer";
import useWeatherLayer from "../hooks/useWeatherLayer";
import useOpenLayersGeocoder from "../hooks/useGeocoder";

import zero_conflict_alert_sound from "../../public/static/files/zero_conflict_alert.mp3";
import { circular } from "ol/geom/Polygon";

const MapComponent = () => {
    const MAP_ID = "main-map";

    const [createConstraintDialogOpen, setCreateConstraintDialogOpen] = useState(false);
    const [createConstraintVertices, setCreateConstraintVertices] = useState([]);

    const [firstResponderConstraintDialogOpen, setFirstResponderConstraintDialogOpen] = useState(false);
    const [firstResponderConstraintCenter, setFirstResponderConstraintCenter] = useState(false);
    const [drawingFirstResponderConstraint, setDrawingFirstResponderConstraint] = useState(false);

    const [flightDetailsVolume, setFlightDetailsVolume] = useState(false);
    const [flightDetailsDialogOpen, setFlightDetailsDialogOpen] = useState(false);

    const [flightCardsOpen, setFlightCardsOpen] = useLocalStorage("flight-cards-open", false);

    const [asdWatchToolbarOpen, setAsdWatchToolbarOpen] = useLocalStorage("watch-toolbar-open", false);
    const [asdWatchToolbarData, setAsdWatchToolbarData] = useLocalStorage("watch-toolbar-data", {
        track_id: "",
        callsign: "",
        track_angle: "",
        ground_speed_kn: "",
        alt_agl_ft: "",
        alt_msl_ft: "",
        zero_conflict_alerts: [],
        alert_conflict_alert: null
    });

    const [audioAlertsEnabled, setAudioAlertsEnabled] = useLocalStorage("audio-alerts-enabled", false);

    const [activeZeroConflictionAlerts, setActiveZeroConflictionAlerts] = useState(new Map());
    const [activeAlertVolumeConflictionAlerts, setActiveAlertVolumeConflictionAlerts] = useState(new Map());

    const [conversationDialogOpen, setConversationDialogOpen] = useState(false);
    const [editFlightVolume, setEditFlightVolume] = useState(false);
    const [editFlightOpen, setEditFlightOpen] = useState(false);

    const [mapSettingsOpen, setMapSettingsOpen] = useState(false);
    const [mapSettingsAnchorEl, setMapSettingsAnchorEl] = useState(null);

    const [mapLayersOpen, setMapLayersOpen] = useState(false);
    const [mapLayersAnchorEl, setMapLayersAnchorEl] = useState(null);

    const [mapTileDropdownOpen, setMapTileDropdownOpen] = useState(false);
    const [mapTileDropdownAnchorEl, setMapTileDropdownAnchorEl] = useState(null);

    const [constraintEditDialogOpen, setConstraintEditDialogOpen] = useState(false);
    const [constraintEditEntity, setConstraintEditEntity] = useState(null);

    const [asdEditDialogOpen, setAsdEditDialogOpen] = useState(false);
    const [asdEditEntity, setAsdEditEntity] = useState(null);

    // for map layers
    const [altitudeLayerRange, setAltitudeLayerRange] = useState([0, 18000]);

    const [asdSublayers, setAsdSublayers] = useState([]);
    const [asdVectorLayerVisible, setAsdVectorLayerVisible] = useState(true);
    const [weatherVectorLayerVisible, setWeatherVectorLayerVisible] = useState(false);

    const [airspaceVectorLayerVisible, setAirspaceVectorLayerVisible] = useState(true);
    const [airspaceSubLayers, setAirspaceSublayers] = useState([
        { local_type: "CLASS_B", visible: true },
        { local_type: "CLASS_C", visible: true },
        { local_type: "CLASS_D", visible: true },
        { local_type: "CLASS_E2", visible: true }
    ]);

    const [operationVectorLayerVisible, setOperationVectorLayerVisible] = useState(true);
    const [constraintVectorLayerVisible, setConstraintVectorLayerVisible] = useState(true);
    const [alertVolumeVectorLayerVisible, setAlertVolumeVectorLayerVisible] = useState(true);
    const [sensorVectorLayerVisible, setSensorVectorLayerVisible] = useState(true);

    const [mapContainer, setMapContainer] = useState(null);
    const map_wrapper_ref = useRef(null);

    const { userMapSettings, user, snackbar, setSnackbar } = useUserAuth();
    const { publishedOperations, constraints, alertVolumes, socket } = useSocket();
    const { sensors, getDrones } = useMap();

    const map_location_ref = useRef(null);
    const hovered_asd_features = useRef([]);
    const volumeUuidToZoomTo = useRef("");

    const location = useLocation();
    const navigate = useNavigate();

    const map = useOpenLayersMap(MAP_ID, editFlightOpen);

    useAirspaceLayer(map, airspaceSubLayers);
    useOpenLayersGeocoder(map);
    useWeatherLayer(map);

    useEffect(() => {
        getDrones();
    }, []);

    useEffect(() => {
        if (location.state && location.state.volumeUuidToZoomTo) {
            volumeUuidToZoomTo.current = location.state.volumeUuidToZoomTo;
        } else {
            volumeUuidToZoomTo.current = "";
        }
    }, [location]);

    useEffect(() => {
        let interval = 0;
        if (!map) {
            return;
        }
        const layer_names = ["asd", "flights", "constraints", "alerts", "sensors"];
        layer_names.forEach((layer_name) => {
            const vector_source = new VectorSource();
            const vector_layer = new VectorLayer({
                source: vector_source,
                zIndex: 10,
                properties: {
                    name: layer_name,
                    selectable: true
                }
            });
            if (layer_name === "asd") {
                vector_layer.setZIndex(15);
            }
            map.addLayer(vector_layer);
        });
        interval = setInterval(() => {
            const source = GetSource(map, "asd");
            if (source) {
                const features = source.getFeatures();
                if (Array.isArray(features)) {
                    const current_time_ms = Date.now();
                    const ten_seconds_ms = 1000 * 10;

                    features.forEach((feature) => {
                        const feature_last_update_ms = feature.get("last_asd_update_ms");
                        if (current_time_ms - feature_last_update_ms > ten_seconds_ms) {
                            source.removeFeature(feature);
                        }
                    });
                }
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [map]);

    useEffect(() => {
        const interval = setInterval(() => {
            const current_timestamp_ms = new Date().getTime();
            const ten_seconds_ms = 1000 * 10;

            // expire zero conflict alerts that haven't been updated in 10s
            activeZeroConflictionAlerts.forEach((zero_conflict_alerts) => {
                zero_conflict_alerts.forEach((zero_conflict_alert) => {
                    const alert_timestamp_ms = new Date(zero_conflict_alert.timestamp).getTime();
                    if (current_timestamp_ms - alert_timestamp_ms > ten_seconds_ms) {
                        const expiration_message = { ...zero_conflict_alert, state: "EXPIRED" };
                        handleZeroConflictionMessage(expiration_message);
                    }
                });
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [activeZeroConflictionAlerts]);

    useEffect(() => {
        let timer_id = null;
        if (!map) {
            return;
        }
        map.on("pointermove", ({ originalEvent, pixel }) => {
            const coordinates = map.getEventCoordinate(originalEvent);
            if (map_location_ref.current) {
                map_location_ref.current.innerHTML = `( ${coordinates[1]}, ${coordinates[0]} )`;
            }
            if (userMapSettings.label_visible === "off") {
                clearTimeout(timer_id);

                hovered_asd_features.current.forEach((feature) => {
                    const current_style = feature.getStyle();
                    const properties = feature.getProperties();

                    if (Array.isArray(current_style) && "asd" in properties) {
                        current_style.forEach((style) => {
                            style.setText(undefined);
                        });
                    }
                    feature.set("show_label", false);
                });
                hovered_asd_features.current = [];

                timer_id = setTimeout(() => {
                    map.forEachFeatureAtPixel(pixel, (feature, layer) => {
                        const asd_layer = GetLayer(map, "asd");
                        if (layer === asd_layer) {
                            const properties = feature.getProperties();
                            const current_style = feature.getStyle();

                            if (Array.isArray(current_style) && "asd" in properties) {
                                if (current_style[0].getImage()) {
                                    const text = getLabelTextFromFeatureAndUser(feature, user);
                                    const style = new Style({ text: text });

                                    feature.setStyle([...current_style, style]);
                                    feature.set("show_label", true);
                                    hovered_asd_features.current.push(feature);
                                }
                            }
                        }
                    });
                }, 250);
            }
        });
        return () => {
            if (timer_id !== null) {
                clearInterval(timer_id);
            }
        };
    }, [map, userMapSettings]);

    // update features when the map settings change
    useEffect(() => {
        if (!map) {
            return;
        }
        const base_layer = GetLayer(map, "base");
        if (base_layer) {
            base_layer.setOpacity(userMapSettings.brightness || 0);
        }
        const weather_layer = GetLayer(map, "weather");
        if (weather_layer) {
            weather_layer.setOpacity(userMapSettings.weather_opacity || 0);
        }
        const asd_track_layer_features = GetFeatures(map, "asd");
        if (Array.isArray(asd_track_layer_features)) {
            asd_track_layer_features.forEach((feature) => {
                const style = getAsdFeatureStyleFromLayersAndSettings(feature, altitudeLayerRange, asdVectorLayerVisible, userMapSettings, user);
                feature.setStyle(style);
            });
        }
        const all_volumes = ["flights", "constraints", "alerts"];
        all_volumes.forEach((volume) => {
            const layer_features = GetFeatures(map, volume);
            if (Array.isArray(layer_features)) {
                layer_features.forEach((feature) => {
                    const style = getGeometryStyleFromFeatureAndSettings(feature, userMapSettings);
                    feature.setStyle(style);
                });
            }
        });
    }, [map, userMapSettings, altitudeLayerRange]);

    // draw radar footprints
    useEffect(() => {
        if (!map) {
            return;
        }
        const sensor_source = GetSource(map, "sensors");
        if (sensor_source) {
            sensor_source.clear();

            sensors.forEach((sensor) => {
                if (isNaN(sensor.lat) || isNaN(sensor.lon) || isNaN(sensor.radius)) {
                    return;
                }
                const center_coord = [sensor.lon, sensor.lat];
                const radius = sensor.radius;
                const geometry = circular(center_coord, radius, 128);

                const feature = new Feature({
                    truth_source: sensor.truth_source,
                    geometry: geometry
                });
                feature.set("name", sensor.name);
                feature.set("sensor", sensor);

                const style = getGeometryStyleFromColor("#000000");
                feature.setStyle(style);

                sensor_source.addFeature(feature);
            });
        }
    }, [map, sensors]);

    useEffect(() => {
        if (!map) {
            return;
        }
        const layer_names = [
            { layer_name: "flights", volumes: publishedOperations, volume_id_to_zoom_to: "" },
            { layer_name: "constraints", volumes: constraints, volume_id_to_zoom_to: volumeUuidToZoomTo.current },
            { layer_name: "alerts", volumes: alertVolumes, volume_id_to_zoom_to: "" }
        ];
        layer_names.map(({ layer_name, volumes, volume_id_to_zoom_to }) => {
            const layer = GetLayer(map, layer_name);
            if (layer) {
                const source = layer.getSource();
                if (source) {
                    source.clear();

                    const features = ConvertCmpVolumesToFeatures(volumes, userMapSettings);
                    source.addFeatures(features);

                    // remove labels for alert volumes that are associated with an operation
                    features.forEach((feature) => {
                        const properties = feature.getProperties();
                        if ("alert_volume" in properties && properties.alert_volume.flight_uuid) {
                            const style = feature.getStyle();
                            style.setText(undefined);
                        }
                    });
                    if (volume_id_to_zoom_to && volume_id_to_zoom_to.length > 0) {
                        const features_to_zoom_to = [];
                        features.forEach((feature) => {
                            const feature_id = feature.getId();
                            if (feature_id.includes(volume_id_to_zoom_to)) {
                                features_to_zoom_to.push(feature);
                            }
                        });
                        if (Array.isArray(features_to_zoom_to) && features_to_zoom_to.length) {
                            JumpToFeatures(map, features_to_zoom_to);
                        } else {
                            setSnackbar({ children: "Constraint has been removed", severity: "error" });
                        }
                        volumeUuidToZoomTo.current = "";
                        navigate("/map", { state: { volumeUuidToZoomTo: "" } });
                    }
                }
            }
        });
    }, [map, publishedOperations, constraints, alertVolumes]);

    useEffect(() => {
        const handleAsdMessage = (asd_messages) => {
            const new_asd_sublayers = [];
            asd_messages.forEach((message) => {
                if (isNaN(message.alt_hae_ft)) {
                    message.alt_hae_ft = 1;
                }
                const last_asd_update_ms = new Date(message.timestamp).getTime();

                const track_feature_ids = [
                    { id: `${message.id}`, coordinates: [message.lon_deg, message.lat_deg] },
                    { id: `${message.id}_pl`, coordinates: getPredLineCoordinatesFromTrackAndSettings(message, userMapSettings) },
                    { id: `${message.id}_hl`, coordinates: getHistLineCoordinatesFromTrack(message) },
                    { id: `${message.id}_dpz`, center: [message.lon_deg, message.lat_deg] }
                ];
                track_feature_ids.forEach(({ id, coordinates, center }, i) => {
                    const asd_track_feature = GetFeature(map, "asd", id);
                    if (asd_track_feature) {
                        asd_track_feature.set("asd", message);

                        const geometry = asd_track_feature.getGeometry();
                        if (geometry) {
                            if (coordinates) {
                                geometry.setCoordinates(coordinates);
                            } else if (center) {
                                geometry.setCenter(center);
                            }
                        }
                        const style = getAsdFeatureStyleFromLayersAndSettings(
                            asd_track_feature,
                            altitudeLayerRange,
                            asdVectorLayerVisible,
                            userMapSettings,
                            user
                        );
                        asd_track_feature.setStyle(style);
                        asd_track_feature.set("last_asd_update_ms", last_asd_update_ms);

                        // update zero confliction lines if applicable
                        if (i === 0) {
                            updateZeroConflictLineFeature(map, asd_track_feature);
                        }
                    } else {
                        const source = GetSource(map, "asd");
                        if (source) {
                            let geometry = undefined;
                            if (id.includes("_pl") || id.includes("_hl")) {
                                geometry = new LineString(coordinates);
                            } else if (id.includes("_dpz") && center) {
                                const radius_deg = 609.6 / 111320;
                                geometry = new Circle(center, radius_deg);
                            } else {
                                geometry = new Point(coordinates);
                            }
                            const new_feature = new Feature({
                                geometry: geometry
                            });
                            new_feature.setProperties({
                                name: message.label,
                                asd: message,
                                last_asd_update_ms: last_asd_update_ms
                            });
                            new_feature.setId(id);

                            const style = getAsdFeatureStyleFromLayersAndSettings(
                                new_feature,
                                altitudeLayerRange,
                                asdVectorLayerVisible,
                                userMapSettings,
                                user
                            );
                            new_feature.setStyle(style);
                            source.addFeature(new_feature);
                        }
                    }
                });

                // update map layer display
                let surveillance_source_label = "";
                const surveillance_source_map = userMapSettings.source_mapping[message.source_id];

                if (surveillance_source_map && surveillance_source_map.visibility !== false) {
                    surveillance_source_label = surveillance_source_map.identifier ? surveillance_source_map.identifier : message.source_id;
                } else if (!surveillance_source_map) {
                    surveillance_source_label = message.source_id;
                }
                if (surveillance_source_label.length > 0) {
                    const name_already_added = [...asdSublayers, ...new_asd_sublayers].find(({ label }) => {
                        return label === surveillance_source_label;
                    });
                    if (!name_already_added) {
                        new_asd_sublayers.push({ label: surveillance_source_label });
                    }
                }
                // update watch toolbar if applicable
                if (message.id === asdWatchToolbarData.track_id) {
                    const callsign = message.callsign[user.organization_id];
                    setAsdWatchToolbarData((prev) => {
                        return {
                            ...prev,
                            callsign: callsign ? callsign : message.label,
                            track_angle: message.heading_deg,
                            ground_speed_kn: message.ground_speed_kn,
                            alt_agl_ft: message.alt_agl_ft,
                            alt_msl_ft: message.alt_msl_ft
                        };
                    });
                }
            });
            if (new_asd_sublayers.length) {
                setAsdSublayers((prev) => [...prev, ...new_asd_sublayers]);
            }
        };
        socket.on("asd", handleAsdMessage);

        socket.on("zero_conflict", (message) => {
            handleZeroConflictionMessage(message);
        });
        socket.on("airspace_alerting_conflicts", (message) => {
            const alert_volume_found = alertVolumes.find(({ uuid }) => {
                return message.volume_id === uuid;
            });
            if (alert_volume_found) {
                handleAlertVolumeConflictionMessage(message);
            }
        });
        return () => {
            socket.off("asd", handleAsdMessage);
            socket.off("zero_conflict");
            socket.off("airspace_alerting_conflicts");
        };
    }, [
        map,
        activeZeroConflictionAlerts,
        activeAlertVolumeConflictionAlerts,
        asdWatchToolbarData,
        altitudeLayerRange,
        asdVectorLayerVisible,
        userMapSettings,
        user,
        asdSublayers,
        alertVolumes
    ]);

    // interval to periodically clear alert volume messages
    useEffect(() => {
        let interval = null;
        if (!map) {
            return;
        }
        interval = setInterval(() => {
            const current_time_ms = Date.now();
            setActiveAlertVolumeConflictionAlerts((prev) => {
                const updated_active_alert_volume_confliction_alerts = new Map(prev);
                updated_active_alert_volume_confliction_alerts.forEach((value, key) => {
                    const alert_time_ms = new Date(value.time_of_alert).getTime();
                    if (current_time_ms - alert_time_ms > 5000) {
                        const feature = GetFeature(map, "asd", key);
                        if (feature) {
                            feature.unset("alert_volume_confliction_alert");
                        }
                        setAsdWatchToolbarData((prev) => {
                            if (key === prev.track_id) {
                                return { ...prev, alert_conflict_alert: null };
                            } else {
                                return prev;
                            }
                        });
                        updated_active_alert_volume_confliction_alerts.delete(key);
                    }
                });
                return updated_active_alert_volume_confliction_alerts;
            });
        }, 1000);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [map]);

    const handleZeroConflictionMessage = (message) => {
        if (!map) {
            return;
        }
        const ownship_id = message.ownship_track.id;
        const ownship_feature = GetFeature(map, "asd", ownship_id);

        const intruder_id = message.intruder_track.id;
        const intruder_feature = GetFeature(map, "asd", intruder_id);

        // update ownship and intruder feature styles
        const features = [ownship_feature, intruder_feature];
        features.forEach((feature) => {
            if (feature) {
                updateFeaturesZeroConflictionAlerts(feature, message);
                const style = getAsdFeatureStyleFromLayersAndSettings(feature, altitudeLayerRange, asdVectorLayerVisible, userMapSettings, user);
                feature.setStyle(style);
            }
        });
        // create or update the line
        const asd_vector_source = GetSource(map, "asd");
        if (ownship_feature && intruder_feature && asd_vector_source) {
            const zero_conflict_line_id = `${ownship_id}_${intruder_id}`;
            const zero_conflict_line_feature = GetFeature(map, "asd", zero_conflict_line_id);
            if (zero_conflict_line_feature) {
                if (message.state === "ACTIVE") {
                    const updated_style = getZeroConflictionLineStyleFromZeroConflictMessage(message, ownship_feature);
                    zero_conflict_line_feature.setStyle(updated_style);
                } else if (message.state === "EXPIRED") {
                    asd_vector_source.removeFeature(zero_conflict_line_feature);
                }
            } else {
                const new_zero_conflict_line_feature = createZeroConflictionLineFeature(ownship_feature, intruder_feature, message);
                asd_vector_source.addFeature(new_zero_conflict_line_feature);
            }
        }
        // update state, flight cards, and watch toolbar
        setActiveZeroConflictionAlerts((prev) => {
            const updated_active_zero_confliction_alerts = new Map(prev);
            if (message.state === "ACTIVE") {
                const current_zero_confliction_alerts = updated_active_zero_confliction_alerts.get(ownship_id);
                if (current_zero_confliction_alerts) {
                    const alert_already_exists = current_zero_confliction_alerts.find(({ alert_uuid }) => {
                        return alert_uuid === message.alert_uuid;
                    });
                    if (alert_already_exists) {
                        const updated_zero_conflict_alerts = current_zero_confliction_alerts.map((existing_alert) => {
                            if (existing_alert.alert_uuid === message.alert_uuid) {
                                if (existing_alert.severity !== message.severity && message.severity !== "CAUTION") {
                                    handleNewZeroConflictionOrAlertVolumeConflictionAlert();
                                }
                                return message;
                            } else {
                                return existing_alert;
                            }
                        });
                        updated_active_zero_confliction_alerts.set(ownship_id, updated_zero_conflict_alerts);
                    } else {
                        updated_active_zero_confliction_alerts.set(ownship_id, [...current_zero_confliction_alerts, message]);
                        handleNewZeroConflictionOrAlertVolumeConflictionAlert();
                    }
                } else {
                    handleNewZeroConflictionOrAlertVolumeConflictionAlert();
                    updated_active_zero_confliction_alerts.set(ownship_id, [message]);
                }
            } else if (message.state === "EXPIRED") {
                const current_zero_confliction_alerts = updated_active_zero_confliction_alerts.get(ownship_id);
                if (current_zero_confliction_alerts) {
                    const updated_zero_conflict_alerts = current_zero_confliction_alerts.filter(({ alert_uuid }) => {
                        return alert_uuid !== message.alert_uuid;
                    });
                    if (updated_zero_conflict_alerts.length === 0) {
                        updated_active_zero_confliction_alerts.delete(ownship_id);
                    } else {
                        updated_active_zero_confliction_alerts.set(ownship_id, updated_zero_conflict_alerts);
                    }
                }
            }
            if (asdWatchToolbarData.track_id === ownship_id) {
                const updated_zero_conflict_alerts = updated_active_zero_confliction_alerts.get(ownship_id);
                if (Array.isArray(updated_zero_conflict_alerts)) {
                    setAsdWatchToolbarData((prev) => {
                        return { ...prev, zero_conflict_alerts: updated_zero_conflict_alerts };
                    });
                } else {
                    setAsdWatchToolbarData((prev) => {
                        return { ...prev, zero_conflict_alerts: [] };
                    });
                }
            }
            return updated_active_zero_confliction_alerts;
        });
    };
    const handleAlertVolumeConflictionMessage = (message) => {
        if (!map) {
            return;
        }
        const vehicle_id = message.track_id;
        const updated_active_alert_volume_confliction_alerts = new Map(activeAlertVolumeConflictionAlerts);

        const vehicle_feature = GetFeature(map, "asd", vehicle_id);
        if (vehicle_feature) {
            if (!vehicle_feature.get("alert_volume_confliction_alert")) {
                const prev_style = vehicle_feature.getStyle();
                if (Array.isArray(prev_style)) {
                    const alert_volume_confliction_style = getAlertVolumeConflictionAlertStyle(vehicle_feature);
                    vehicle_feature.setStyle([...prev_style, alert_volume_confliction_style]);
                }
            }
            vehicle_feature.set("alert_volume_confliction_alert", message);
        }
        if (asdWatchToolbarData.track_id === vehicle_id) {
            const updated_asd_watch_toolbar_data = {
                ...asdWatchToolbarData,
                alert_conflict_alert: message
            };
            setAsdWatchToolbarData(updated_asd_watch_toolbar_data);
        }
        if (asdWatchToolbarData.track_id === vehicle_id && updated_active_alert_volume_confliction_alerts.has(vehicle_id) === false) {
            handleNewZeroConflictionOrAlertVolumeConflictionAlert();
        }
        updated_active_alert_volume_confliction_alerts.set(vehicle_id, message);
        setActiveAlertVolumeConflictionAlerts(updated_active_alert_volume_confliction_alerts);
    };
    const handleHomeButtonClick = () => {
        if (map && map.getView()) {
            map.getView().setCenter([userMapSettings.longitude, userMapSettings.latitude]);
            map.getView().setZoom(12);
        }
    };
    const handleCreateConstraintButtonClick = () => {
        const source = new VectorSource({
            wrapX: false
        });
        const draw = new Draw({
            source: source,
            type: "Polygon"
        });
        map.addInteraction(draw);

        draw.on("drawend", ({ feature }) => {
            setCreateConstraintDialogOpen(true);
            map.removeInteraction(draw);

            const coordinates = feature.getGeometry().getCoordinates();
            const vertices = coordinates[0].map((arr) => {
                return { lng: arr[0], lat: arr[1] };
            });
            setCreateConstraintVertices([{ id: 0, vertices: vertices }]);
        });
    };
    const handleFirstResponderConstraintButtonClick = () => {
        const source = new VectorSource({
            wrapX: false
        });
        const draw = new Draw({
            source: source,
            type: "Point"
        });
        map.addInteraction(draw);
        setDrawingFirstResponderConstraint(true);

        draw.on("drawend", ({ feature }) => {
            map.removeInteraction(draw);
            setDrawingFirstResponderConstraint(false);
            setFirstResponderConstraintDialogOpen(true);

            const coordinates = feature.getGeometry().getCoordinates();
            const center = {
                lon_deg: coordinates[0],
                lat_deg: coordinates[1]
            };
            setFirstResponderConstraintCenter(center);
        });
    };
    const handleToggleLayersButtonClick = (e) => {
        setMapLayersAnchorEl(e.currentTarget);
        setMapLayersOpen((prev) => !prev);
    };
    const handleLayerToggleButtonClick = (layer, setVectorLayerVisibility) => {
        const updatedVisibility = !layer.getVisible();
        layer.setVisible(updatedVisibility);
        setVectorLayerVisibility(updatedVisibility);
    };
    const handleSettingsButtonClick = (e) => {
        setMapSettingsOpen((prev) => !prev);
        setMapSettingsAnchorEl(e.currentTarget);
    };
    const handleFlightCardsButtonClick = () => {
        setFlightCardsOpen((prev) => !prev);
    };
    const handleWatchEntityButtonClick = (asd_uuid) => {
        setAsdWatchToolbarOpen((prev) => {
            if (!prev === true) {
                const features = GetFeatures(map, "asd");
                if (Array.isArray(features)) {
                    features.forEach((feature) => {
                        const properties = feature.getProperties();
                        if (properties.asd && properties.asd.id === asd_uuid) {
                            let callsign = properties.asd.callsign[user.organization_id];
                            if (!callsign) {
                                callsign = properties.asd.label;
                            }
                            let zero_conflict_alerts = [];
                            const active_zero_conflict_alerts = activeZeroConflictionAlerts.get(asd_uuid);
                            if (Array.isArray(active_zero_conflict_alerts)) {
                                zero_conflict_alerts = active_zero_conflict_alerts;
                            }
                            let alert_conflict_alert = null;
                            if (activeAlertVolumeConflictionAlerts.has(asd_uuid)) {
                                alert_conflict_alert = activeAlertVolumeConflictionAlerts.get(asd_uuid);
                            }
                            setAsdWatchToolbarData({
                                track_id: properties.asd.id,
                                callsign: callsign,
                                track_angle: properties.asd.heading_deg,
                                ground_speed_kn: properties.asd.ground_speed_kn,
                                alt_agl_ft: properties.asd.alt_agl_ft,
                                alt_msl_ft: properties.asd.alt_msl_ft,
                                zero_conflict_alerts: zero_conflict_alerts,
                                alert_conflict_alert: alert_conflict_alert
                            });
                        }
                    });
                }
            } else {
                setAsdWatchToolbarData({
                    track_id: "",
                    callsign: "",
                    track_angle: "",
                    ground_speed_kn: "",
                    alt_agl_ft: "",
                    alt_msl_ft: "",
                    zero_conflict_alerts: [],
                    alert_conflict_alert: null
                });
            }
            return !prev;
        });
    };
    const handleCheckFlightASDVisible = (asd_uuid) => {
        const feature_is_on_map = GetFeature(map, "asd", asd_uuid);
        if (feature_is_on_map) {
            return true;
        } else {
            return false;
        }
    };
    const handleConstraintEditButtonClick = (feature) => {
        const properties = feature.getProperties();
        const constraint_edit_entity = {
            constraint: properties.constraint
        };
        setConstraintEditEntity(constraint_edit_entity);
        setConstraintEditDialogOpen(true);
    };
    const handleTagButtonClick = (feature) => {
        const properties = feature.getProperties();

        let callsign = properties.name;
        if (properties.asd.callsign && properties.asd.callsign[user.organization_id] !== undefined) {
            callsign = properties.asd.callsign[user.organization_id];
        }
        let show_dpz = false;
        if (properties.asd.show_dpz && properties.asd.show_dpz[user.organization_id] !== undefined) {
            show_dpz = properties.asd.show_dpz[user.organization_id];
        }
        let daa_config_uuid = "";
        if (properties.asd.daa_configs && properties.asd.daa_configs[user.organization_id] !== undefined) {
            daa_config_uuid = properties.asd.daa_configs[user.organization_id];
        }
        const asd_edit_entity = {
            name: callsign,
            id: properties.asd.id,
            show_dpz: show_dpz,
            flight_uuid: properties.asd.flight_uuid,
            daa_config_uuid: daa_config_uuid,
            history_locations: properties.asd.history_locations
        };
        setAsdEditEntity(asd_edit_entity);
        setAsdEditDialogOpen(true);
    };
    const handleAltitudeLayerRangeChanged = async (e, value) => {
        const updated_altitude_layer_range = value;
        setAltitudeLayerRange(updated_altitude_layer_range);
    };
    const handleJumpToOperationButtonClick = (flight_uuid) => {
        const features = GetAllFeaturesWithId(map, "flights", flight_uuid);
        JumpToFeatures(map, features);
    };
    const handleToggleAirspaceLayers = (local_types, value) => {
        const updated_sublayers = airspaceSubLayers.map((setting) => {
            if (local_types.includes(setting.local_type)) {
                return {
                    ...setting,
                    visible: value
                };
            } else {
                return setting;
            }
        });
        setAirspaceSublayers(updated_sublayers);

        if (updated_sublayers.every(({ visible }) => visible === updated_sublayers[0].visible)) {
            setAirspaceVectorLayerVisible(updated_sublayers[0].visible);
        }
        const airspaces = GetLayer(map, "LAANC_AIRSPACE");
        if (!airspaces) {
            return;
        }
        airspaces.setStyle((feature) => {
            return GetLaancAirspaceStyle(feature, updated_sublayers);
        });
    };
    const handleShowEntityDpz = (message) => {
        const asd_track_layer_features = GetFeatures(map, "asd");
        if (!Array.isArray(asd_track_layer_features)) {
            return;
        }
        asd_track_layer_features.forEach((feature) => {
            const properties = feature.getProperties();
            if ("asd" in properties && properties.asd.id === message.id) {
                const updated_callsigns = {
                    ...message.callsign,
                    [user.organization_id]: message.callsign
                };
                const updated_dpzs = {
                    ...message.show_dpz,
                    [user.organization_id]: message.show_dpz
                };
                const updated_daa_configs = {
                    ...message.daa_configs,
                    [user.organization_id]: message.alerting_params_uuid
                };
                const updated_asd = {
                    ...properties.asd,
                    callsign: updated_callsigns,
                    show_dpz: updated_dpzs,
                    flight_uuid: message.flight_uuid,
                    daa_configs: updated_daa_configs
                };
                feature.set("asd", updated_asd);

                const style = getAsdFeatureStyleFromLayersAndSettings(feature, altitudeLayerRange, asdVectorLayerVisible, userMapSettings, user);
                feature.setStyle(style);
            }
        });
    };
    const handleNewZeroConflictionOrAlertVolumeConflictionAlert = () => {
        if (audioAlertsEnabled === true) {
            const alert_audio = new Audio(zero_conflict_alert_sound);
            alert_audio.play();
        }
    };
    const handleMapTileDropdownButtonClick = (e) => {
        setMapTileDropdownOpen((prev) => !prev);
        setMapTileDropdownAnchorEl(e.currentTarget);
    };
    const handleFullScreenButtonClick = () => {
        try {
            if (!document.fullscreenElement && map_wrapper_ref.current) {
                map_wrapper_ref.current.requestFullscreen();

                if (!mapContainer) {
                    setMapContainer(map_wrapper_ref.current);
                }
            } else if (document.fullscreenElement) {
                document.exitFullscreen();
            }
        } catch (err) {
            console.error(err);
        }
    };
    return editFlightOpen ? (
        <FlightCreator
            setFlightCreatorOpen={setEditFlightOpen}
            flightUUID={editFlightVolume.flight_uuid}
            publishedFlight={true}
            flightState={editFlightVolume.state}
        />
    ) : (
        <Box ref={map_wrapper_ref} sx={{ width: "100%", position: "relative", overflowX: "hidden" }}>
            <Box sx={{ display: "flex", flex: 1, height: "100%" }}>
                <Box sx={{ flex: 1, position: "relative", height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <EntityWatchToolbar
                        asdWatchToolbarOpen={asdWatchToolbarOpen}
                        asdWatchToolbarData={asdWatchToolbarData}
                        handleWatchEntityButtonClick={handleWatchEntityButtonClick}
                        audioAlertsEnabled={audioAlertsEnabled}
                        setAudioAlertsEnabled={setAudioAlertsEnabled}
                    />
                    <Box sx={{ flex: 1, position: "relative" }}>
                        <MapFullScreen
                            map_id={MAP_ID}
                            map={map}
                            buttons={[
                                {
                                    id: "viewHome",
                                    name: "View Home",
                                    visible: true,
                                    icon: <HomeIcon />,
                                    onClick: handleHomeButtonClick
                                },
                                {
                                    id: "createConstraint",
                                    name: "Create Constraint",
                                    visible: user.user_role === "Admin" || user.user_role === "Airspace Manager",
                                    icon: <EditIcon />,
                                    onClick: handleCreateConstraintButtonClick
                                },
                                {
                                    id: "toggleLayers",
                                    name: "Toggle Layers",
                                    visible: true,
                                    icon: <LayersIcon />,
                                    onClick: handleToggleLayersButtonClick
                                },
                                {
                                    id: "flightCards",
                                    name: "Flight Cards",
                                    visible: true,
                                    icon: <StyleIcon />,
                                    onClick: handleFlightCardsButtonClick
                                },
                                {
                                    id: "editSettings",
                                    name: "Edit Settings",
                                    visible: true,
                                    icon: <SettingsIcon />,
                                    onClick: handleSettingsButtonClick
                                },
                                {
                                    id: "toggleMapTiles",
                                    name: "Map Tiles",
                                    visible: true,
                                    icon: <MapIcon />,
                                    onClick: handleMapTileDropdownButtonClick
                                },
                                {
                                    id: "toggleFullscreen",
                                    name: document.fullscreenElement ? "Exit Fullscreen" : "Enter Fullscreen",
                                    visible: true,
                                    icon: document.fullscreenElement ? <CloseIcon /> : <OpenInFullIcon />,
                                    onClick: handleFullScreenButtonClick
                                }
                            ]}
                        >
                            <MapInfoCard
                                map={map}
                                showButtons={true}
                                handleWatchEntityButtonClick={handleWatchEntityButtonClick}
                                handleTagButtonClick={handleTagButtonClick}
                                handleEditConstraintButtonClick={handleConstraintEditButtonClick}
                                airspaceSubLayers={airspaceSubLayers}
                                mapContainer={mapContainer}
                            />
                        </MapFullScreen>
                        <Typography
                            ref={map_location_ref}
                            variant="caption"
                            sx={{ position: "absolute", right: "0.5em", bottom: ".5em", zIndex: 1, textShadow: "0 0 10px #000" }}
                        />
                    </Box>
                </Box>

                <Collapse in={flightCardsOpen} orientation="horizontal" unmountOnExit sx={{ height: "100%", overflowY: "auto" }}>
                    <MapFlightCards
                        operations={publishedOperations}
                        flightDetailsDialogOpen={flightDetailsDialogOpen}
                        setFlightDetailsVolume={setFlightDetailsVolume}
                        setFlightDetailsDialogOpen={setFlightDetailsDialogOpen}
                        setConversationDialogOpen={setConversationDialogOpen}
                        setEditFlightVolume={setEditFlightVolume}
                        setEditFlightDialogOpen={setEditFlightOpen}
                        handleWatchEntity={handleWatchEntityButtonClick}
                        handleCheckFlightASDVisible={handleCheckFlightASDVisible}
                        setFlightCardsOpen={setFlightCardsOpen}
                        handleJumpToOperation={handleJumpToOperationButtonClick}
                        flightCardsOpen={flightCardsOpen}
                        activeAlertVolumeConflictionAlerts={activeAlertVolumeConflictionAlerts}
                        activeZeroConflictionAlerts={activeZeroConflictionAlerts}
                    />
                </Collapse>
            </Box>
            {asdEditDialogOpen === true ? (
                <EntityEditDialog
                    operations={publishedOperations}
                    asdEditDialogOpen={asdEditDialogOpen}
                    setASDEditDialogOpen={setAsdEditDialogOpen}
                    asdEditEntity={asdEditEntity}
                    handleShowEntityDpz={handleShowEntityDpz}
                    mapContainer={mapContainer}
                />
            ) : (
                <></>
            )}
            {constraintEditDialogOpen === true ? (
                <ConstraintEdit
                    snackbar={snackbar}
                    constraintEditEntity={constraintEditEntity}
                    constraintEditDialogOpen={constraintEditDialogOpen}
                    setSnackbar={setSnackbar}
                    setConstraintEditDialogOpen={setConstraintEditDialogOpen}
                    mapContainer={mapContainer}
                />
            ) : (
                <></>
            )}
            {createConstraintDialogOpen === true ? (
                <ConstraintDraw
                    snackbar={snackbar}
                    constraintCreateVertices={createConstraintVertices}
                    constraintCreateDialogOpen={createConstraintDialogOpen}
                    setSnackbar={setSnackbar}
                    setConstraintCreateDialogOpen={setCreateConstraintDialogOpen}
                    mapContainer={mapContainer}
                />
            ) : (
                <></>
            )}
            {firstResponderConstraintDialogOpen === true ? (
                <ConstraintCircleCreateDialog
                    snackbar={snackbar}
                    constraintCircleCenterPoint={firstResponderConstraintCenter}
                    constraintCircleCreateDialogOpen={firstResponderConstraintDialogOpen}
                    setConstraintCircleCreateDialogOpen={setFirstResponderConstraintDialogOpen}
                    setSnackbar={setSnackbar}
                    mapContainer={mapContainer}
                />
            ) : (
                <></>
            )}
            {user.user_role === "First Responder" ? (
                <Fab
                    color="primary"
                    onClick={handleFirstResponderConstraintButtonClick}
                    disabled={drawingFirstResponderConstraint}
                    sx={{ position: "absolute", bottom: "40px", right: "10px", zIndex: "unset" }}
                    id="firstResponderConstraint"
                >
                    <AddIcon />
                </Fab>
            ) : (
                <></>
            )}

            {flightDetailsDialogOpen ? (
                <Suspense fallback={<></>}>
                    <LAANCDetailsDialog
                        flightDetailsVolume={flightDetailsVolume}
                        flightDetailsDialogOpen={flightDetailsDialogOpen}
                        publishedOperations={publishedOperations}
                        setFlightDetailsVolume={setFlightDetailsVolume}
                        setFlightDetailsDialogOpen={setFlightDetailsDialogOpen}
                        mapContainer={mapContainer}
                    />
                </Suspense>
            ) : (
                <></>
            )}
            {conversationDialogOpen ? (
                <ConversationDialog
                    conversationDialogOpen={conversationDialogOpen}
                    setConversationDialogOpen={setConversationDialogOpen}
                    mapContainer={mapContainer}
                />
            ) : (
                <></>
            )}
            {mapSettingsOpen ? (
                <MapSettingsDropdown
                    mapSettingsOpen={mapSettingsOpen}
                    setMapSettingsOpen={setMapSettingsOpen}
                    mapSettingsAnchorEl={mapSettingsAnchorEl}
                    setSnackbar={setSnackbar}
                    mapContainer={mapContainer}
                />
            ) : (
                <></>
            )}

            <MapLayersDropdown
                mapLayersOpen={mapLayersOpen}
                setMapLayersOpen={setMapLayersOpen}
                mapLayersAnchorEl={mapLayersAnchorEl}
                altitudeLayerRange={altitudeLayerRange}
                handleAltitudeLayerRangeChanged={handleAltitudeLayerRangeChanged}
                sections={[
                    {
                        title: "General",
                        layers: [
                            {
                                label: "Surveillance Sources",
                                checked: asdVectorLayerVisible,
                                handleLayerClick: () => handleLayerToggleButtonClick(GetLayer(map, "asd"), setAsdVectorLayerVisible),
                                sublayers: asdSublayers
                            },
                            {
                                label: "Weather Radar",
                                checked: weatherVectorLayerVisible,
                                handleLayerClick: () => handleLayerToggleButtonClick(GetLayer(map, "weather"), setWeatherVectorLayerVisible),
                                sublayers: []
                            }
                        ]
                    },
                    {
                        title: "Volumes",
                        layers: [
                            {
                                label: "Airspaces",
                                checked: airspaceSubLayers.every(({ visible }) => visible === true),
                                indeterminate: !airspaceSubLayers.every(({ visible }) => visible === airspaceSubLayers[0].visible),
                                handleLayerClick: () =>
                                    handleToggleAirspaceLayers(
                                        airspaceSubLayers.map(({ local_type }) => local_type),
                                        !airspaceVectorLayerVisible
                                    ),
                                sublayers: airspaceSubLayers.map(({ local_type, visible }) => {
                                    return {
                                        label: local_type,
                                        checked: visible,
                                        handleSublayerClick: () => handleToggleAirspaceLayers([local_type], !visible)
                                    };
                                })
                            },
                            {
                                label: "Operations",
                                checked: operationVectorLayerVisible,
                                handleLayerClick: () => handleLayerToggleButtonClick(GetLayer(map, "flights"), setOperationVectorLayerVisible),
                                sublayers: []
                            },
                            {
                                label: "Constraints",
                                checked: constraintVectorLayerVisible,
                                handleLayerClick: () => handleLayerToggleButtonClick(GetLayer(map, "constraints"), setConstraintVectorLayerVisible),
                                sublayers: []
                            },
                            {
                                label: "Alert Volumes",
                                checked: alertVolumeVectorLayerVisible,
                                handleLayerClick: () => handleLayerToggleButtonClick(GetLayer(map, "alerts"), setAlertVolumeVectorLayerVisible),
                                sublayers: []
                            },
                            {
                                label: "Radar Footprint",
                                checked: sensorVectorLayerVisible,
                                handleLayerClick: () => handleLayerToggleButtonClick(GetLayer(map, "sensors"), setSensorVectorLayerVisible),
                                sublayers: []
                            }
                        ]
                    }
                ]}
                mapContainer={mapContainer}
            />
            <MapTileDropdown
                mapTileDropdownOpen={mapTileDropdownOpen}
                setMapTileDropdownOpen={setMapTileDropdownOpen}
                mapTileDropdownAnchorEl={mapTileDropdownAnchorEl}
                mapContainer={mapContainer}
            />
        </Box>
    );
};

export default MapComponent;
