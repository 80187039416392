import { useEffect, useRef, useState } from "react";

import OpenLayersMap from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";

import { useGeographic } from "ol/proj";

import { useUserAuth } from "../contexts/authContext";

import { useLocalStorage } from "./useLocalStorage";

export default function useMap(map_element_id, rerender) {
    const { userMapSettings, mapTileOptions } = useUserAuth();

    const [mapLocation, setMapLocation] = useLocalStorage("map-location", {
        center: [userMapSettings.longitude, userMapSettings.latitude],
        zoom: 12
    });
    const [map, setMap] = useState(null);

    const settingsRef = useRef(userMapSettings);

    useGeographic();

    useEffect(() => {
        let selected_map_tile = mapTileOptions.find(({ index }) => {
            return userMapSettings.map_preference === index;
        });
        let source = null;
        if (selected_map_tile) {
            source = selected_map_tile.source;
        } else {
            source = new OSM();
        }
        const tile_layer = new TileLayer({
            source: source,
            opacity: settingsRef.current.brightness,
            properties: { name: "base" }
        });
        if (userMapSettings.map_preference === 1) {
            tile_layer.on("prerender", (evt) => {
                if (evt.context) {
                    const context = evt.context;
                    context.globalCompositeOperation = "source-over";
                    context.filter = "grayscale(80%) invert(100%)";
                }
            });
            tile_layer.on("postrender", (evt) => {
                if (evt.context) {
                    const context = evt.context;
                    context.filter = "none";
                }
            });
        } else if (userMapSettings.map_preference === 4) {
            tile_layer.setMinZoom(8);
        }
        const map = new OpenLayersMap({
            target: map_element_id,
            layers: [tile_layer],
            view: new View({
                center: mapLocation.center,
                zoom: mapLocation.zoom
            }),
            controls: []
        });
        map.getViewport().addEventListener("contextmenu", (e) => e.preventDefault());
        setMap(map);
    }, [rerender]);

    // store map location in the browser
    useEffect(() => {
        let debounceTimer;
        const handleViewChange = () => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
                const view = map.getView();
                setMapLocation({ center: view.getCenter(), zoom: view.getZoom() });
            }, 500);
        };
        if (map) {
            map.getView().on("change:center", handleViewChange);
            map.getView().on("change:resolution", handleViewChange);
        }
        return () => {
            if (map) {
                map.getView().un("change:center", handleViewChange);
                map.getView().un("change:resolution", handleViewChange);
            }
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
        };
    }, [map]);

    useEffect(() => {
        if (!map) return;

        const layers = map.getLayers().getArray();
        layers.forEach((layer) => {
            const layer_name = layer.get("name");
            if (layer_name !== "base") return;

            const updated_base_layer_setting = mapTileOptions.find(({ index }) => {
                return userMapSettings.map_preference === index;
            });
            if (!updated_base_layer_setting) return;

            layer.on("prerender", (evt) => {
                if (evt.context) {
                    const context = evt.context;
                    context.globalCompositeOperation = "source-over";
                    context.filter = userMapSettings.map_preference === 1 ? "grayscale(80%) invert(100%)" : "grayscale(0%) invert(0%)";
                }
            });
            layer.on("postrender", (evt) => {
                if (evt.context) {
                    const context = evt.context;
                    context.filter = "none";
                }
            });
            const source = updated_base_layer_setting.source;
            layer.setSource(source);

            const opacity = userMapSettings.brightness;
            layer.setOpacity(opacity || 0);

            setTimeout(() => {
                source.refresh();
            }, 100);
        });
    }, [userMapSettings]);

    return map;
}
