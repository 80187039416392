import Feature from "ol/Feature";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Polygon from "ol/geom/Polygon";

import { booleanPointInPolygon, buffer, point } from "@turf/turf";
import { GetFirstStyle, STYLE_VOLUME_MONITOR, ConvertGeometryToGeoJson, GetFeature, GetFeatures, GetSource } from "../map/mapUtil";

export function StartAssetMonitoring(map, assetUUID) {
    if (!map) {
        return null;
    }

    // Invalid asset -- nothing to monitor.
    const asset = GetFeature(map, "assets", assetUUID);
    if (!asset) {
        return null;
    }

    const geoJson = ConvertGeometryToGeoJson(asset.getGeometry());
    const buffered = buffer(geoJson, 10000, { units: "meters" });
    const bufferedFeature = new Feature(new Polygon(buffered.geometry.coordinates));
    bufferedFeature.setId(`${assetUUID}-monitor`);
    bufferedFeature.setProperties({ assetFeature: asset, cuas: [], alerts: 0, severeAlerts: 0 });
    bufferedFeature.setStyle(STYLE_VOLUME_MONITOR);

    const assetMonitorSource = GetSource(map, "assetMonitors");
    assetMonitorSource.addFeature(bufferedFeature);
    map.getView().fit(assetMonitorSource.getExtent(), { padding: [200, 200, 200, 200] });
    RemoveEntitiesOutsideAssetMonitors(map);
    return bufferedFeature;
}

export function StopAssetMonitoring(map, assetUUID) {
    const assetMonitorSource = GetSource(map, "assetMonitors");
    const assetMonitor = assetMonitorSource.getFeatureById(`${assetUUID}-monitor`);
    if (assetMonitor) {
        assetMonitorSource.removeFeature(assetMonitor);
        assetMonitor.get("cuas").forEach((entity) => entity.setStyle(GetFirstStyle(entity)));
        RemoveEntitiesOutsideAssetMonitors(map);
        return assetMonitor;
    }
}

export function StopAllAssetMonitoring(map) {
    GetSource(map, "assetMonitors").clear();
    GetSource(map, "asd").forEachFeature((entity) => entity.setStyle(GetFirstStyle(entity)));
}

export function MonitorCUAS(map, assetMonitor) {
    const assetGeoJson = ConvertGeometryToGeoJson(assetMonitor.get("assetFeature").getGeometry());
    const assetBufferGeoJson = ConvertGeometryToGeoJson(assetMonitor.getGeometry());

    const counterUAS = [];
    let alerts = 0;
    let severeAlerts = 0;

    GetSource(map, "asd").forEachFeature((entity) => {
        let entityCoords = entity.getGeometry().getCoordinates();
        if (Array.isArray(entityCoords[0])) {
            entityCoords = entityCoords[0];
        }

        const entityPoint = point(entityCoords);
        let alertColor;

        // RED ALERT -- Inside the asset.
        if (booleanPointInPolygon(entityPoint, assetGeoJson)) {
            counterUAS.push(entity);
            alertColor = "red";
            severeAlerts++;
        }

        // YELLOW ALERT -- Inside buffer zone, but outside asset.
        else if (booleanPointInPolygon(entityPoint, assetBufferGeoJson)) {
            counterUAS.push(entity);
            alertColor = "yellow";
            alerts++;
        }

        // NO ALERT -- Ignore.
        else {
            return;
        }

        entity.setStyle([
            GetFirstStyle(entity),
            new Style({
                image: new CircleStyle({
                    radius: 25,
                    stroke: new Stroke({
                        color: alertColor,
                        width: 2
                    })
                })
            })
        ]);
    });

    if (assetMonitor.get("alerts") !== alerts) {
        assetMonitor.set("alerts", alerts);
    }
    if (assetMonitor.get("severeAlerts") !== severeAlerts) {
        assetMonitor.set("severeAlerts", severeAlerts);
    }
    assetMonitor.set("cuas", counterUAS);
}

function RemoveEntitiesOutsideAssetMonitors(map) {
    const entitySource = GetSource(map, "asd");
    entitySource.forEachFeature((entity) => {
        if (IsOutsideAssetMonitors(map, entity.getGeometry().getCoordinates())) {
            entitySource.removeFeature(entity);
        }
    });
}

export function IsOutsideAssetMonitors(map, coordinates) {
    const assetMonitors = GetFeatures(map, "assetMonitors");
    if (!assetMonitors || assetMonitors.length === 0) {
        return false;
    }
    for (const assetMonitor of assetMonitors) {
        const geoJson = ConvertGeometryToGeoJson(assetMonitor.getGeometry());
        if (booleanPointInPolygon(point(coordinates), geoJson)) {
            return false;
        }
    }
    return true;
}
